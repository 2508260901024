import { apiService } from "../store.service";
import type { PaginationSettings } from "../../components/common/Pagination/Pagination.types";
import type { GetUserProfileResponseType, PasswordChangePostModel, PasswordResetCodeValidationPostModel, PasswordResetPostModel, PasswordResetRequestCodePostModel, SetUserLanguagePreferencePayloadType, UpdateUserProfilePayloadType, UpdateUserProfileResponseType, UserPostModel, UserPutModel, UserResponseModel } from "./users.types";

import { addCompanyUserConfig, changeCurrentUserPasswordConfig, deleteCompanyUserConfig, getCompanyUsersConfig, getUserProfileConfig, passwordRequestCodeConfig, passwordVerifyCodeConfig, resetPasswordConfig, setUserLanguagePreferenceConfig, updateCompanyUserConfig, updateUserProfileConfig } from "./users.config";

export const UsersApi = apiService
  .enhanceEndpoints({
    addTagTypes: ["Users", "UserProfile"]
  })
  .injectEndpoints({
    endpoints: build => ({
      getCompanyUsers: build.query<
      {
        data: UserResponseModel[],
        meta: { pagination: PaginationSettings }
      },
      { pageNumber?: number }
      >({
        query: ({ pageNumber }) => ({
          ...getCompanyUsersConfig,
          params: {
            PageNumber: pageNumber,
            PageSize: 10,
            IncludeCount: true,
          }
        }),
        transformResponse: (
          response: UserResponseModel[],
          meta: Record<string, never>,
        ): { data: UserResponseModel[], meta: { pagination: PaginationSettings }} => ({
          data: response,
          meta: {
            pagination: JSON.parse(meta.headers["x-pagination"]) as PaginationSettings
          }
        }),
        providesTags: ["Users"],
      }),
      addCompanyUser: build.mutation<void, UserPostModel>({
        query: (data) => ({
          ...addCompanyUserConfig,
          data
        }),
        invalidatesTags: ["Users"],
      }),
      updateCompanyUser: build.mutation<void, { data: UserPutModel, userId: number }>({
        query: ({ data, userId }) => ({
          ...updateCompanyUserConfig(userId),
          data
        }),
        invalidatesTags: ["Users"],
      }),
      deleteCompanyUser: build.mutation<void, { userId: number }>({
        query: ({ userId }) => ({
          ...deleteCompanyUserConfig(userId),
        }),
        invalidatesTags: ["Users"],
      }),
      setLanguagePreference: build.mutation<void, SetUserLanguagePreferencePayloadType>({
        query: ({ languageId }) => ({
          ...setUserLanguagePreferenceConfig,
          data: {
            languageId
          }
        })
      }),
      changeUserPassword: build.mutation<void, PasswordChangePostModel>({
        query: ({ currentPassword, newPassword }) => ({
          ...changeCurrentUserPasswordConfig,
          data: {
            currentPassword,
            newPassword
          },
        }),
      }),
      passwordRequestCode: build.mutation<void, PasswordResetRequestCodePostModel>({
        query: ({ email }) => ({
          ...passwordRequestCodeConfig,
          params: {
            email
          },
        }),
      }),
      passwordVerifyCode: build.mutation<void, PasswordResetCodeValidationPostModel>({
        query: ({ email, code }) => ({
          ...passwordVerifyCodeConfig,
          data: {
            email,
            code
          },
        }),
      }),
      passwordReset: build.mutation<void, PasswordResetPostModel>({
        query: ({ email, code, newPassword }) => ({
          ...resetPasswordConfig,
          data: {
            email,
            code,
            newPassword
          },
        }),
      }),
      getUserProfile: build.query<GetUserProfileResponseType, void>({
        query: () => ({
          ...getUserProfileConfig,
        }),
        providesTags: ["UserProfile"]
      }),
      updateUserProfile: build.mutation<UpdateUserProfileResponseType, UpdateUserProfilePayloadType>({
        query: ({ firstName, lastName }) => ({
          ...updateUserProfileConfig,
          data: {
            firstName,
            lastName
          }
        }),
        invalidatesTags: ["UserProfile"]
      }),
    }),
  });

export const {
  useLazyGetCompanyUsersQuery,
  useAddCompanyUserMutation,
  useUpdateCompanyUserMutation,
  useDeleteCompanyUserMutation,
  useSetLanguagePreferenceMutation,
  useChangeUserPasswordMutation,
  usePasswordRequestCodeMutation,
  usePasswordVerifyCodeMutation,
  usePasswordResetMutation,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
} = UsersApi