import type { TFunction } from "i18next"
import * as yup from "yup"

import type { RegisterFormState } from "./RegisterForm.types"

const PASSWORD_LENGTH = 8

export const RegisterFormValidationSchema = (translate: TFunction): yup.ObjectSchema<RegisterFormState> => (
  yup.object().shape({
    address1: yup
      .string()
      .required(translate("errors:validation:requiredField")),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], translate("errors:validation:passwordNotMatch"))
      .required(translate("errors:validation:requiredField")),
    countryId: yup
      .object({
        label: yup.string().required(),
        value: yup.number().required()
      })
      .required(translate("errors:validation:requiredField")),
    email: yup
      .string()
      .email(translate("errors:validation:email"))
      .required(translate("errors:validation:requiredField")),
    firstName: yup
      .string(),
    lastName: yup
      .string(),
    name: yup
      .string()
      .required(translate("errors:validation:requiredField")),
    password: yup
      .string()
      .required(translate("errors:validation:requiredField"))
      .min(PASSWORD_LENGTH, translate("errors:validation:passwordLength", { count: PASSWORD_LENGTH })),
    postCode: yup
      .string(),
    terms: yup
      .boolean()
      .required(translate("errors:validation:agreement"))
      .oneOf([true], translate("errors:validation:agreement")),
    town: yup
      .string()
      .required(translate("errors:validation:requiredField")),
  })
)