import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import { useAppSelector } from "../../../hooks/storeHooks";
import { selectUserData } from "../../../store/auth/auth.selectors";
import { RouteConfig } from "../../../components/App/Routes/routes.config";
import { NewPasswordForm } from "./NewPasswordForm/NewPasswordForm.component";

export const NewPasswordPage = () => {
  const { t } = useTranslation()
  const userData = useAppSelector(selectUserData)

  if (!userData.forcePasswordChange) {
    return <Navigate to={RouteConfig.LOGIN.fullPath} replace />
  }

  return (
    <Box>
      <Typography
        variant="h1"
        fontSize={32}
        fontWeight={700}
        color="text.dark"
        mb={2}
      >
        {t("user:newPasswordHeader")}
      </Typography>

      <Typography
        variant="body1"
        fontSize={16}
        lineHeight="24px"
        color="text.secondary"
        mb={4}
      >
        {t("user:newPasswordSubheader")}
      </Typography>

      <NewPasswordForm />
    </Box>
  )
}