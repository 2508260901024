import { NavLink, Outlet } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

import { RouteConfig } from "../../components/App/Routes/routes.config";
import { AppLogo } from "../../components/AppLogo/AppLogo.component";
import { StyledBackground, StyledContentLayerContainer, StyledContentLayerGrid, StyledSloganLayerContainer, StyledSloganLayerGrid } from "./PublicLayout.styles";

export const PublicLayout = () => (
  <>
    <StyledBackground />

    <Grid
      container
      height="100%"
      position="fixed"
    >
      <StyledContentLayerGrid item xs={6}>
        <StyledContentLayerContainer
          maxWidth="xl"
          disableGutters
        >
          <Box component={NavLink} to={RouteConfig.LOGIN.fullPath}>
            <AppLogo
              width="100%"
              maxWidth={220}
              mb={6}
            />
          </Box>
            
          <Box my="auto" pb={10}>
            <Outlet />
          </Box>
        </StyledContentLayerContainer>
      </StyledContentLayerGrid>

      <StyledSloganLayerGrid item xs={6} >
        <StyledSloganLayerContainer
          maxWidth="xl"
          disableGutters
        >
          <Typography
            variant="h1"
            component="span"
            color="white"
            fontSize={72}
            lineHeight={1.1}
            fontWeight={700}
            letterSpacing="-0.02px"
            maxWidth={450}
          >
              Your journey to net-zero starts here
          </Typography>
        </StyledSloganLayerContainer>
      </StyledSloganLayerGrid>
    </Grid>
  </>
)
