import { theme } from "../../../theme/theme"
import { createFallbackColorGenerator, getFallbackColors } from "../../../utils/fallbackColors"
import type { RankedCategoryEmission } from "../../../store/widgetContents/widgetContents.types"

export const parseEmissionsByCategory = (aggregates: RankedCategoryEmission[]) => {
  const fallbackColors = getFallbackColors(theme.palette.chart)
  const colorGenerator = createFallbackColorGenerator(fallbackColors)

  return aggregates.map(({
    rank,
    carbonEquivalent,
    name,
    colour
  }) => ({
    id: rank,
    value: carbonEquivalent,
    name: `${name}`,
    color: colour ? `#${colour}` : colorGenerator(),
  }))
}