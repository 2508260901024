import type { UserGuideSimpleResponseModel } from "../../../store/widgetContents/widgetContents.types";

export const extractFileNameFromUrl = (url: string): string => {
  const parts = url.split("/");
  const index = parts.lastIndexOf("UserGuides");
  const fileName = parts[index + 1];

  return fileName;
}

export const parseResponse = (data?: { [key: string]: UserGuideSimpleResponseModel }) => {
  if (!data) return []

  return Object.keys(data).map(key => ({
    name: data[key].name,
    fileName: extractFileNameFromUrl(data[key].path),
    description: data[key].description
  }));
}