import type { FC} from "react";
import { useEffect, useMemo,useRef, useState } from "react";
import { Box, Typography } from "@mui/material";

import { useGetRankedEmissionsByCategoriesQuery } from "../../../store/widgetContents/widgetContents.api";
import { HorizontalBarChart } from "../../charts/HorizontalBarChart/HorizontalBarChart.component";
import { Loader } from "../../common/Loader/Loader.component";
import { ErrorTile } from "../ErrorTile/ErrorTile.component";
import { EmissionsByCategoryTileNoData } from "./EmissionsByCategoryTileNoData/EmissionsByCategoryTileNoData.component";
import { parseEmissionsByCategory } from "./EmissionsByCategoryTile.utils";
import { StyledPaper } from "../widgets.styles";

export const EmissionsByCategoryTile: FC<{ title: string }> = ({ title }) => {
  const chartWrapperRef = useRef<HTMLDivElement | null>(null)
  const [height, setHeight] = useState<number | null>(null)
  const {
    data: emissionsByCategory,
    isLoading: emissionsByCategoryLoading,
    error: emissionsByCategoryError
  } = useGetRankedEmissionsByCategoriesQuery(undefined, { refetchOnMountOrArgChange: true })

  const parsedEmissions = useMemo(() => emissionsByCategory?.categories
    ? parseEmissionsByCategory(emissionsByCategory.categories)
    : [], [emissionsByCategory?.categories])

  useEffect(() => {
    if (chartWrapperRef.current) {
      setHeight(chartWrapperRef.current.offsetHeight)
    }
  }, [chartWrapperRef, emissionsByCategory])

  if (emissionsByCategoryLoading) {
    return (
      <StyledPaper>
        <Loader withoutText my="auto" />
      </StyledPaper>
    )
  }

  if (emissionsByCategoryError) {
    return <ErrorTile title={title} />
  }

  if (!parsedEmissions.length) {
    return <EmissionsByCategoryTileNoData title={title} />
  }

  return (
    <StyledPaper>
      <Typography
        variant="h2"
        color="text.dark"
      >
        {title}
      </Typography>

      <Box
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        ref={chartWrapperRef}
      >
        <HorizontalBarChart
          data={parsedEmissions}
          unit={emissionsByCategory?.unit}
          height={height}
        />
      </Box>
    </StyledPaper>
  )
}