import type { FC } from "react";
import { Text, View } from "@react-pdf/renderer";

import { isTableDataModel } from "../PDFTable/PDFTable.component";
import type { ParagraphReportModel } from "../../../../store/reportHeaders/reportHeaders.types";
import { PDFTableSimpleStyles } from "./PDFSimpleTable.styles";

export type PDFSimpleTableProps = {
  paragraph: ParagraphReportModel
}

export const PDFSimpleTable: FC<PDFSimpleTableProps> = ({ paragraph }) => {
  if (!isTableDataModel(paragraph.data)) return null

  return (
    <View style={PDFTableSimpleStyles.table} wrap={false}>
      {paragraph.data.columns.map((column) => (
        <View key={column.name} style={PDFTableSimpleStyles.tableRow}> 
          <Text style={PDFTableSimpleStyles.tableLabelCell}>
            {column.title}
          </Text>
          <Text style={PDFTableSimpleStyles.tableValueCell}>
            {isTableDataModel(paragraph.data) && paragraph.data.rows[0]?.[column.name]?.value}
          </Text>
        </View> 
      ))}
    </View>
  );
}