import type { FC } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";

import { Button } from "../../../components/common/Button/Button.component";
import { TextFieldController } from "../../../components/common/TextFieldController/TextFieldController.component";

import { useUserPasswordChangeForm } from "./UserPasswordChangeForm.hooks";

export const UserPasswordChangeForm: FC = () => {
  const { t } = useTranslation()
  const { formMethods, isLoading, handleSubmit, handleCancel } = useUserPasswordChangeForm()

  return (
    <>
      <Typography
        variant="h2"
        mb={1}
      >
        {t("user:profile:passwordChangeHeader")}
      </Typography>
      <FormProvider {...formMethods}>
        <Grid
          container
          component="form"
          direction="column"
          spacing={2}
          onSubmit={handleSubmit}
        >
          <Grid item>
            <TextFieldController
              name="currentPassword"
              label={t("form:label:currentPassword")}
              placeholder={t("form:placeholder:currentPassword")}
              type="password"
              disabled={isLoading}
            />
          </Grid>
          <Grid item>
            <TextFieldController
              name="newPassword"
              label={t("form:label:newPassword")}
              placeholder={t("form:placeholder:newPassword")}
              type="password"
              disabled={isLoading}
            />
          </Grid>
          <Grid item>
            <TextFieldController
              name="confirmPassword"
              label={t("form:label:confirmNewPassword")}
              placeholder={t("form:placeholder:confirmNewPassword")}
              type="password"
              disabled={isLoading}
            />
          </Grid>
          <Grid container item spacing={4}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
                disabled={!formMethods.formState.isValid}
              >
                {t("common:cancel")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                fullWidth
                disabled={!formMethods.formState.isValid}
              >
                {t("common:saveChanges")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  )
}
