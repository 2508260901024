import { RouteConfig } from "../../App/Routes/routes.config"
import type { Scope3InputFormSimpleResponseModel } from "../../../store/scope3InputForms/scope3InputForms.types"
import type { SubmenuItemsConfigType } from "../SubmenuList/SubmenuList.types"

export const getValueChainSubmenuItems = ( inputForms: Scope3InputFormSimpleResponseModel[]) => inputForms.map(
  ({ displayName, scope3InputFormId, icon }): SubmenuItemsConfigType => ({
    text: displayName,
    navigateTo: RouteConfig.VALUE_CHAIN.fullPath.replace(":inputFormId?", scope3InputFormId.toString()),
    disabled: false,
    iconName: icon
  })
)