import type { FC} from "react";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import type { AxiosResponse } from "axios";
import dayjs from "dayjs";

import { useAppDispatch } from "../../../../hooks/storeHooks";
import { useYupResolver } from "../../../../hooks/useYupResolver";
import i18n from "../../../../services/i18n/i18n";
import { useLazyLogInByTokenQuery, useLogInMutation } from "../../../../store/auth/auth.api";
import { setUserLanguageId } from "../../../../store/auth/auth.slice";
import { useGetCountriesQuery } from "../../../../store/countries/countries.api";
import { useGetAllAvailableLanguagesQuery } from "../../../../store/languages/languages.api";
import { usePostNewCompanyMutation } from "../../../../store/registrations/registrations.api";
import { useSetLanguagePreferenceMutation } from "../../../../store/users/users.api";
import { RouteConfig } from "../../../../components/App/Routes/routes.config";
import { AutocompleteController } from "../../../../components/common/AutocompleteController/AutocompleteController.component";
import { Button } from "../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import { CheckboxController } from "../../../../components/common/CheckboxController/CheckboxController.component";
import { TextFieldController } from "../../../../components/common/TextFieldController/TextFieldController.component";
import { TextFieldSkeleton } from "../../../../components/common/TextFieldSkeleton/TextFieldSkeleton.component";
import type { GetAllAvailableLanguagesResponseType } from "../../../../store/languages/languages.types";
import type { ApiErrorResponseModel } from "../../../../store/store.types";
import type { RegisterFormProps, RegisterFormState } from "./RegisterForm.types";

import { RegisterFormValidationSchema } from "./RegisterForm.validation";

export const RegisterForm: FC<RegisterFormProps> = ({ token }) => {
  const { t } = useTranslation()
  const locale = localStorage.getItem("i18nextLng")
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { data: countries } = useGetCountriesQuery()
  const { data: availableLanguages } = useGetAllAvailableLanguagesQuery(undefined, { refetchOnMountOrArgChange: true })
  const [setUserLanguagePreference] = useSetLanguagePreferenceMutation()
  const [postNewCompany, { isLoading }] = usePostNewCompanyMutation()
  const [logIn] = useLogInMutation()
  const [loginByToken] = useLazyLogInByTokenQuery()

  const parsedCountries = useMemo(() => countries?.map(({ countryId, name }) => ({
    value: countryId,
    label: name
  })), [countries])

  const form = useForm<RegisterFormState>({
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      name: "",
      countryId: null,
      town: "",
      address1: "",
      postCode: "",
      terms: false,
    },
    mode: "all",
    resolver: useYupResolver(RegisterFormValidationSchema),
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    const payload = {
      ...values,
      token,
      countryId: values.countryId?.value as number,
    }

    try {
      const langCode = availableLanguages?.find( (lang: GetAllAvailableLanguagesResponseType) => lang.code === locale)

      await postNewCompany(payload).unwrap()
      const { email: username, password } = values;
      await logIn({ username, password }).unwrap()

      // // temporarySolution - set language preference
      dispatch(setUserLanguageId(langCode?.languageId))
      await setUserLanguagePreference({ languageId: langCode?.languageId as number }).unwrap()
      dayjs.locale(i18n.language)
      await loginByToken()
      // // e/o temporarySolution - set language preference

      navigate(RouteConfig.DASHBOARD.fullPath);
    } catch (error) {
      const err = error as AxiosResponse<ApiErrorResponseModel>
      const errors = err.data.errors
      const errorDetail = err.data.detail

      if(errors) {
        Object.keys(errors).forEach((fieldName) => {
          const name = fieldName.substring(0, 1).toLowerCase() + fieldName.substring(1)
          
          form.setError(name as keyof RegisterFormState, {
            type: "manual",
            message: errors[fieldName] 
          });
        });
      } else if (errorDetail) {
        form.setError("terms", {
          type: "manual",
          message: errorDetail,
        });
      } else {
        form.setError("terms", {
          type: "manual",
          message: t("errors:loginError"),
        });
      }
    }
  })

  return (
    <>
      <Typography
        variant="body1"
        fontSize={16}
        lineHeight="24px"
        color="text.secondary"
        mb={4}
      >
        {t("user:invitationPage:register:info")}
      </Typography>

      <FormProvider {...form}>
        <Box
          component="form"
          onSubmit={handleSubmit}
        >
          <Box mb={3}>
            <Typography
              variant="h1"
              component="h2"
              color="text.dark"
              mb={2}
            >
              {t("user:invitationPage:register:profileHeader")}
            </Typography>

            <TextFieldController
              name="email"
              label={t("form:label:email")}
              placeholder=""
              autoFocus
              sx={{ mb: 2 }}
              disabled={isLoading}
            />

            <TextFieldController
              type="password"
              name="password"
              label={t("form:label:password")}
              placeholder=""
              disabled={isLoading}
              sx={{ mb: 2 }}
            />

            <TextFieldController
              type="password"
              name="confirmPassword"
              label={t("form:label:confirmPassword")}
              placeholder=""
              disabled={isLoading}
              sx={{ mb: 2 }}
            />

            <TextFieldController
              name="firstName"
              label={t("form:label:firstName")}
              placeholder=""
              disabled={isLoading}
              sx={{ mb: 2 }}
            />

            <TextFieldController
              name="lastName"
              label={t("form:label:lastName")}
              placeholder=""
              disabled={isLoading}
              sx={{ mb: 2 }}
            />
          </Box>

          <Box mb={3}>
            <Typography
              variant="h1"
              component="h2"
              color="text.dark"
              mb={2}
            >
              {t("user:invitationPage:register:companyHeader")}
            </Typography>

            <TextFieldController
              name="name"
              label={t("form:label:companyName")}
              placeholder=""
              sx={{ mb: 2 }}
              disabled={isLoading}
            />

            <Box mb={2}>
              {parsedCountries?.length ? (
                <AutocompleteController
                  name="countryId"
                  label={t("form:label:country")}
                  placeholder={t("form:placeholder:country")}
                  disabled={isLoading}
                  options={parsedCountries}
                />
              ) : <TextFieldSkeleton withLabel />}
            </Box>

            <TextFieldController
              name="town"
              label={t("form:label:town")}
              placeholder=""
              disabled={isLoading}
              sx={{ mb: 2 }}
            />

            <TextFieldController
              name="address1"
              label={t("form:label:address")}
              placeholder=""
              disabled={isLoading}
              sx={{ mb: 2 }}
            />

            <TextFieldController
              name="postCode"
              label={t("form:label:postCode")}
              placeholder=""
              disabled={isLoading}
              sx={{ mb: 2 }}
            />
            
            <CheckboxController
              name="terms"
              label={t("user:invitationPage:register:agreement")}
              disabled={isLoading}
            />
          </Box>

          <Button
            type="submit"
            fullWidth
            size="large"
            endIcon={isLoading ? <ButtonLoader /> : null}
            disabled={isLoading}
          >
            {t("user:invitationPage:register:submit")}
          </Button>
        </Box>
      </FormProvider>
    </>
  )
}