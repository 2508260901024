import { Box, styled } from "@mui/material";

export const NiceHTML = styled(Box)(({ theme }) => ({
  fontSize: "1rem",
  color: theme.palette.grey[500],
  lineHeight: 1.42,
  
  "h1": { fontSize: "2.2rem" },
  "h2": { fontSize: "2.0rem" },
  "h3": { fontSize: "1.8rem" },
  "h4": { fontSize: "1.6rem" },
  "h5": { fontSize: "1.4rem" },
  "h6": { fontSize: "1.2rem" },
  
  "h1, h2, h3, h4, h5, h6": {
    color: theme.palette.text.dark,
    marginBottom: "1rem",
  },

  "ul": {
    listStyleType: "disc",
    listStylePosition: "outside",
    margin: ".5rem 0 0 4ch",
  },
  
  "ol": {
    listStyleType: "decimal",
    listStylePosition: "outside",
    margin: ".5rem 0 0 4ch"
  },
  
  "ul ul, ol ul": {
    listStyleType: "circle",
    listStylePosition: "outside",
    marginLeft: "15px"
  },
  
  "ol ol, ul ol": {
    listStyleType: "lower-latin",
    listStylePosition: "outside",
    marginLeft: "15px"
  },
  
  "ul, ol, p": {
    marginBottom: "1rem"
  },
  
  "li": {
    marginBottom: ".5rem"
  },
}))