import { theme } from "../../../theme/theme"
import { createFallbackColorGenerator, getFallbackColors } from "../../../utils/fallbackColors"
import type { RankedEmission } from "../../../store/widgetContents/widgetContents.types"

export const parseTopEmissionSources = (aggregates: RankedEmission[]) => {
  const fallbackColors = getFallbackColors(theme.palette.chart)
  const colorGenerator = createFallbackColorGenerator(fallbackColors)
    
  return aggregates.map(({
    rank,
    carbonEquivalent,
    entityName,
    colour
  }) => ({
    id: rank,
    value: carbonEquivalent,
    name: entityName,
    color: colour ? `#${colour}` : colorGenerator(),
  }))
}