import type { FC} from "react";
import { useState } from "react"
import { useController } from "react-hook-form"
import { Box } from "@mui/material";

import { DatePicker } from "../DatePicker/DatePicker.component"
import SvgIcon from "../SvgIcon/SvgIcon.component";
import { Tooltip } from "../Tooltip/Tooltip.component";
import type { DatePickerControllerProps } from "./DatePickerController.types"

export const DatePickerController: FC<DatePickerControllerProps> = ({
  name,
  label,
  placeholder = label,
  required = false,
  fullWidth = true,
  isDisabled = false,
  tooltipText,
  ...props
}) => {
  const [iconName, setIconName] = useState<"qm-filled" | "qm-stroke">("qm-stroke")
  const labelType = required ? `${label} *` : label
  const { field, fieldState: { error, invalid } } = useController({ name })

  return (
    <DatePicker
      label={tooltipText ? (
        <Tooltip
          title={tooltipText}
          placement="top-start"
          color={isDisabled ? "disabled" : "dark"}
        >
          <Box
            display="flex"
            alignItems="center"
            onMouseEnter={() => setIconName("qm-filled")}
            onMouseLeave={() => setIconName("qm-stroke")}
          >
            {labelType}
            <SvgIcon
              name={iconName}
              color="grey.400"
              sx={{ ml: 0.5 }}
            />
          </Box>
        </Tooltip>
      ) : labelType
      }
      placeholder={placeholder}
      helperText={error?.message}
      error={invalid}
      fullWidth={fullWidth}
      isDisabled={isDisabled}
      {...field}
      ref={null}
      {...props}
      id={name}
    />
  )
}
