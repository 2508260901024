import { theme } from "../../../theme/theme";
import { createFallbackColorGenerator, getFallbackColors } from "../../../utils/fallbackColors";
import type { ElectricityByTypesAggregatesResponseModel } from "../../../store/widgetContents/widgetContents.types";

export const parseData = (data?: ElectricityByTypesAggregatesResponseModel) => {
  if (!data) return []

  const fallbackColors = getFallbackColors(theme.palette.chart)
  const colorGenerator = createFallbackColorGenerator(fallbackColors)

  return data.types.map(type => ({
    id: `${type.name.replace(/ /gu, "-")}-${type.valuePercentage}`,
    value: type.valuePercentage,
    name: type.name,
    color: type.colour.length ? type.colour : colorGenerator()
  }));
};