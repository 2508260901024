import { type FC } from "react";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { alpha, Box, Grid, styled, useTheme } from "@mui/material";

import type { ScenarioVariableSimpleResponseModel } from "../../../../../../../../store/scenariosSections/scenariosSections.api";
import { AutocompleteController } from "../../../../../../../../components/common/AutocompleteController/AutocompleteController.component";
import { Button } from "../../../../../../../../components/common/Button/Button.component";
import SvgIcon from "../../../../../../../../components/common/SvgIcon/SvgIcon.component";
import { TextFieldController } from "../../../../../../../../components/common/TextFieldController/TextFieldController.component";
import { createFieldArrayObject } from "../../ScenarioWizard.utils";

type FieldArrayProps = {
  field: ScenarioVariableSimpleResponseModel
  disabled: boolean
}

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey[200],
  color: theme.palette.text.primary,
  justifyContent: "flex-start",
  m: 0,
  mt: 1,
  "&:hover": {
    background: theme.palette.grey[100],
  },
  "&:focus": {
    background: theme.palette.grey[100],
  },
  "&:disabled": {
    boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary[100]}`,
    background: theme.palette.grey[200],
    color: alpha(theme.palette.text.primary, .16),
    "& svg, & path": {
      stroke: alpha(theme.palette.text.primary, .16),
    },
  },
}))

export const FieldArray: FC<FieldArrayProps> = ({ field, disabled }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const { fields, append, remove } = useFieldArray({
    name: field.code
  });

  return (
    <>
      {fields.map((item, index) => (
        <Box
          key={item.id}
          p={1.5}
          border={`1px solid ${theme.palette.grey[200]}`}
        >
          <Grid
            container
            wrap="nowrap"
            columnSpacing={1}
          >
            <Grid item xs={3}>
              <AutocompleteController
                name={`${field.code}[${index}].type`}
                options={field.choices.map(({ value, displayString }) => ({ value, label: displayString }))}
                label={field.label ?? ""}
                placeholder=""
                disableClearable={true}
                sx={{ maxWidth: 250 }}
                disabled={disabled}
                tooltipText={field.label ?? ""}
              />
            </Grid>

            {field.tableDimensions?.map( nestedField => (
              <Grid item key={nestedField.code}>
                <TextFieldController
                  type="number"
                  name={`${field.code}[${index}].${nestedField.code}`}
                  label={nestedField.name ?? ""}
                  placeholder=""
                  sx={{ maxWidth: 100 }}
                  disabled={disabled}
                  tooltipText={nestedField.name ?? ""}
                />
              </Grid>
            ))}
            <Grid item mt={2.25}>
              {index !== 0 ? (
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  startIcon={<SvgIcon name="trash" />}
                  onClick={() => remove(index)}
                  disabled={disabled}
                />
              ) : null}
            </Grid>
          </Grid>
        </Box>
      ))}

      <StyledButton
        fullWidth
        size="small"
        startIcon={<SvgIcon name="add-filled" />}
        onClick={() => append(createFieldArrayObject(field))}
        disabled={disabled}
      >
        {t("common:add")}
      </StyledButton>
    </>
    
  )
}
