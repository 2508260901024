import { useState } from "react";
import { useController } from "react-hook-form"
import { Box } from "@mui/material";

import { Autocomplete } from "../Autocomplete/Autocomplete.component";
import SvgIcon from "../SvgIcon/SvgIcon.component";
import { Tooltip } from "../Tooltip/Tooltip.component";
import type { AutocompleteOption } from "../Autocomplete/Autocomplete.types";
import type { AutocompleteControllerProps } from "./AutocompleteController.types";

export const AutocompleteController = <
  Value,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  options,
  name,
  label,
  placeholder = label,
  required = false,
  fullWidth = true,
  disabled = false,
  handleChange,
  tooltipText,
  ...props
}: AutocompleteControllerProps<Value, Multiple, DisableClearable, FreeSolo>) => {
  const [iconName, setIconName] = useState<"qm-filled" | "qm-stroke">("qm-stroke")
  const labelType = required ? `${label} *` : label
  const {
    field,
    fieldState: { error, invalid }
  } = useController({ name })

  const handleValueChange = (data: AutocompleteOption<Value>) => {
    field.onChange(data)
    handleChange?.(data)
  }

  return (
    <Autocomplete
      id={name}
      options={options}
      label={tooltipText ? (
        <Tooltip
          title={tooltipText}
          placement="top-start"
          color={disabled ? "disabled" : "dark"}
        >
          <Box
            display="flex"
            alignItems="center"
            onMouseEnter={() => setIconName("qm-filled")}
            onMouseLeave={() => setIconName("qm-stroke")}
          >
            {labelType}
            <SvgIcon
              name={iconName}
              color="grey.400"
              sx={{ ml: 0.5 }}
            />
          </Box>
        </Tooltip>
      ) : labelType
      }
      placeholder={placeholder}
      fullWidth={fullWidth}
      disabled={disabled}
      error={invalid}
      helperText={error?.message}
      {...field}
      value={field.value}
      onChange={(_, data) => handleValueChange(data)}
      ref={null}
      {...props}
    />
  )
}
