import type { TFunction } from "i18next";

import { RouteConfig } from "../../App/Routes/routes.config";
import type { SubmenuItemsConfigType } from "../SubmenuList/SubmenuList.types";

export const getSettingsSubmenuItemsConfig = (t: TFunction): SubmenuItemsConfigType[] => [
  {
    text: t("common:menu:menuSettings:companyDetails"),
    navigateTo: RouteConfig.SETTINGS.fullPath,
    disabled: false,
  },
  {
    text: t("common:menu:menuSettings:manageMembers"),
    navigateTo: RouteConfig.SETTINGS_USERS.fullPath,
    disabled: false,
  },
  {
    text: t("common:menu:menuSettings:manageLocations"),
    navigateTo: RouteConfig.SETTINGS_LOCATIONS.fullPath,
    disabled: false,
  },
  {
    text: t("common:menu:menuSettings:paymentHistory"),
    navigateTo: RouteConfig.DASHBOARD.fullPath,
    disabled: true,
  },
  {
    text: t("common:menu:menuSettings:terms"),
    navigateTo: RouteConfig.SETTINGS_TERMS.fullPath,
    disabled: false,
  },
]