import type { FC} from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, List, ListItem, Typography } from "@mui/material";

import SvgIcon from "../../common/SvgIcon/SvgIcon.component";
import { StyledPaper } from "../widgets.styles";
import { StyledLink } from "./ShortcutsTile.styles";

import { shortcutsConfig } from "./ShortcutsTile.config";

export const ShortcutsTile: FC<{ title: string }> = ({ title }) => {
  const { t } = useTranslation()
  const shortcutsItems = useMemo(() => shortcutsConfig(t), [t])

  return (
    <StyledPaper>
      <Typography
        variant="h2"
        color="text.dark"
      >
        {title}
      </Typography>

      <List sx={{ flex: 1 }}>
        {shortcutsItems.map(({ id, label, navigateTo }) => (
          <ListItem
            key={id}
            disableGutters
          >
            <StyledLink to={navigateTo}>
              <Box
                display="flex"
                alignItems="center"
              >
                <Typography
                  variant="body1"
                  fontWeight={500}
                >
                  {label}
                </Typography>
              
                <SvgIcon name="arrow-up-right" sx={{ ml: 0.5 }} />
              </Box>
            </StyledLink>
          </ListItem>  
        ))}
      </List>
    </StyledPaper>
  )
}