import type { FC} from "react";
import { Navigate, Outlet } from "react-router-dom"

import { useAppSelector } from "../../../hooks/storeHooks";
import { selectUserData } from "../../../store/auth/auth.selectors";
import { RouteConfig } from "../Routes/routes.config";

export const ProtectedRoute: FC = () => {
  const userData = useAppSelector(selectUserData);

  if(userData.roleName.toLowerCase() === "view") {
    return <Navigate to={RouteConfig.DASHBOARD.fullPath} replace />
  }

  return <Outlet />
}