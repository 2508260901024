import type { ChangeEvent, FC} from "react";
import { useState } from "react"
import { useController } from "react-hook-form"
import { Box } from "@mui/material"

import SvgIcon from "../SvgIcon/SvgIcon.component"
import { TextField } from "../TextField/TextField.component"
import { Tooltip } from "../Tooltip/Tooltip.component"
import type { TextFieldControllerProps } from "./TextFieldController.types"

export const TextFieldController: FC<TextFieldControllerProps> = ({
  name,
  label,
  placeholder = label,
  required = false,
  fullWidth = true,
  disabled = false,
  maxLength,
  inputComponent,
  formatValue,
  tooltipText,
  ...props
}) => {
  const [iconName, setIconName] = useState<"qm-filled" | "qm-stroke">("qm-stroke")
  const labelType = required ? `${label} *` : label
  const { field, fieldState: { error, invalid } } = useController({ name })

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    field.onChange(formatValue ? formatValue(e.target.value) : e.target.value);
  };

  return (
    <TextField
      label={tooltipText ? (
        <Tooltip
          title={tooltipText}
          placement="top-start"
          color={disabled ? "disabled" : "dark"}
        >
          <Box
            display="flex"
            alignItems="center"
            onMouseEnter={() => setIconName("qm-filled")}
            onMouseLeave={() => setIconName("qm-stroke")}
          >
            {labelType}
            <SvgIcon
              name={iconName}
              color="grey.400"
              sx={{ ml: 0.5 }}
            />
          </Box>
        </Tooltip>
      ) : labelType
      }
      placeholder={placeholder}
      disabled={disabled}
      error={invalid}
      helperText={error?.message}
      inputProps={{
        id: name,
        maxLength,
      }}
      fullWidth={fullWidth}
      maxLength={maxLength}
      InputProps={
        {
          inputComponent,
          onChange: handleChangeValue,
        }
      }
      {...field}
      ref={null}
      {...props}
    />
  )
}
