
import type { FC, SyntheticEvent} from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, List, Typography } from "@mui/material";

import { useGetAdviceListQuery } from "../../../store/advice/advice.api";
import SvgIcon from "../../common/SvgIcon/SvgIcon.component";
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, StyledIndexWrapper, StyledListItem, StyledTypography } from "./SubmenuKnowledgeBase.styles";

export const SubmenuKnowledgeBase: FC = () => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState<number | false>(1);
  const { data } = useGetAdviceListQuery()
  
  const handleChange = (panel: number) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Typography
        variant="h1"
        mb={3}
        lineHeight="40px"
        color="text.dark"
      >
        {t("knowledgeBase:title")}
      </Typography>

      <Typography
        variant="h3"
        color="text.dark"
        fontSize={18}
        mb={2}
      >
        {t("knowledgeBase:tipsSubtitle")}
      </Typography>

      <List>
        {data?.map( ({ adviceId, title, body }, index) => (
          <StyledListItem key={adviceId}>
            <StyledAccordion
              expanded={expanded === adviceId}
              onChange={handleChange(adviceId)}
            >
              <StyledAccordionSummary expandIcon={<SvgIcon name="chevron-down" />}>
                <Box
                  display="flex"
                  alignItems="flex-start"
                >
                  <StyledIndexWrapper>
                    <Typography
                      variant="body3"
                      fontWeight={500}
                      color="primary.700"
                    >
                      {index + 1}
                    </Typography>
                  </StyledIndexWrapper>

                  <StyledTypography
                    variant="body1"
                    fontWeight={500}
                    expanded={expanded === adviceId}
                  >
                    {title}
                  </StyledTypography>
                </Box>
              </StyledAccordionSummary>

              <StyledAccordionDetails>
                <Typography
                  variant="body1"
                  color="text.secondary"
                >
                  {body}
                </Typography>
              </StyledAccordionDetails>
            </StyledAccordion>
          </StyledListItem>
        ))}
      </List>
    </>
  );
}