import type { FC } from "react";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Grid } from "@mui/material";

import { api } from "../../../../../api/rest/api";
import type { AutocompleteOption } from "../../../../../components/common/Autocomplete/Autocomplete.types";
import { AutocompleteController } from "../../../../../components/common/AutocompleteController/AutocompleteController.component";
import { TextFieldSkeleton } from "../../../../../components/common/TextFieldSkeleton/TextFieldSkeleton.component";
import { extractVariableNames, replaceVariablesInUrl } from "../Scope3Form.utils";
import { getDefaultOptions, isUnitResponseModel } from "./Scope3DropdownField.utils";
import type { UnitSimpleResponseModel } from "../../../../../store/units/units.types";
import type { Scope3DropdownFieldProps, Scope3OptionResponseModel } from "./Scope3DropdownField.types";
import { StyledAdornmentAutocompleteController } from "./Scope3DropdownField.styles";

export const Scope3DropdownField: FC<Scope3DropdownFieldProps> = ({ field, inputFormId, disabled, asEndAdornment }) => {
  const form = useFormContext()
  const defaultOptions = getDefaultOptions(field)
  const [options, setOptions] = useState<AutocompleteOption<number | string>[] | null>(defaultOptions)
  const [loading, setLoading] = useState(false)

  const variableNames = extractVariableNames(field.apiPath);
  const requiredValues = useWatch({ name: variableNames })

  const matchNamesWithValues = variableNames.reduce((result, fieldName, index) => ({
    ...result,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    [fieldName]: requiredValues[index]?.value ?? requiredValues[index]
  }), {})

  const requiredVariables = {
    ...matchNamesWithValues,
    scope3InputFormId: inputFormId,
  }

  const fetchOptions = async () => {
    setLoading(true)
    try {
      const { data } = await api.request<Scope3OptionResponseModel[] | UnitSimpleResponseModel[]>({
        method: "GET",
        url: replaceVariablesInUrl(requiredVariables, field.apiPath),
      })

      const parsedOptions = data.map((option) => {
        if (isUnitResponseModel(option)) {
          return {
            value: option.unitId,
            label: option.symbol
          }
        } 
        return {
          value: option.id,
          label: option.displayName
        }
        
      })

      setOptions(parsedOptions)
      setLoading(false)
    } catch(e) {
      setLoading(false)
    }
  }
  
  const isValid = requiredValues.every(item => !!item)

  const obj = form.getValues(field.name)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const exists = options?.some(item => item.value === obj?.value && item.label === obj?.label);
  
  useEffect(() => {
    if (field.apiPath && isValid) {
      fetchOptions()
    }

    if (!exists && exists !== undefined && !!options?.length) {
      form.resetField(field.name)
    } else if (!exists && exists !== undefined && options?.length === 0) {
      form.resetField(field.name, { defaultValue: null })
    } else if (exists && !defaultOptions && !asEndAdornment) {
      form.resetField(field.name, { defaultValue: null })
    }
  }, [requiredValues])

  useEffect(() => {
    if (isValid && options?.length === 1 && !exists && exists !== undefined) {
      form.setValue(field.name, options[0])
    } else if (isValid && options?.length === 0 && !exists && exists !== undefined) {
      form.setValue(field.name, { value: 0, label: "" })
    } else if (isValid && asEndAdornment && options?.length && !exists && exists !== undefined) {
      form.setValue(field.name, options[0])
    }
  }, [options])
        
  return (
    <>
      {asEndAdornment ? (
        <StyledAdornmentAutocompleteController
          name={field.name}
          label=""
          placeholder=""
          options={options ?? []}
          disabled={!isValid || disabled}
          disableClearable={true}
          helperText=""
        />
      ) : (
        <>
          {options?.length === 0 ? null : (
            <Grid item xs={3} minWidth="235px">
              {loading ? <TextFieldSkeleton withLabel /> : (
                <AutocompleteController
                  name={field.name}
                  options={options ?? []}
                  label={field.displayName}
                  tooltipText={field.description}
                  placeholder={field.displayName}
                  disableClearable={true}
                  disabled={!isValid || disabled}
                />
              )}
            </Grid>
          )}
        </>
      )}
    </>
  )
}