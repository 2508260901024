import type { FC} from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";

import earthUrl from "../../../assets/img/earth.png";
import { useGetScopesAggregatesQuery } from "../../../store/widgetContents/widgetContents.api";
import { RouteConfig } from "../../App/Routes/routes.config";
import { Loader } from "../../common/Loader/Loader.component";
import { ErrorTile } from "../ErrorTile/ErrorTile.component";
import { SummaryTileNoData } from "./SummaryTileNoData/SummaryTileNoData.component";
import { StyledPaper } from "../widgets.styles";
import { StyledLink } from "./SummaryTile.styles";

export const SummaryTile: FC<{ title: string }> = ({ title }) => {
  const { t } = useTranslation()
  const {
    data: scopes,
    isLoading,
    error
  } = useGetScopesAggregatesQuery(undefined, { refetchOnMountOrArgChange: true })

  if (isLoading) {
    return (
      <StyledPaper>
        <Loader withoutText my="auto" />
      </StyledPaper>
    )
  }

  if (error) {
    return <ErrorTile title={title} />
  }

  if (!scopes?.entries || !scopes.total) {
    return <SummaryTileNoData title={title} />
  }

  return (
    <StyledPaper>
      <Grid container>
        <Grid item xs={8.5}>
          <Box>
            <Typography
              variant="h2"
              color="text.dark"
              mb={2}
            >
              {title}
            </Typography>

            <Typography
              variant="h1"
              component="p"
              fontSize={40}
              lineHeight="40px"
              color="text.dark"
              mb={2}
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {scopes.formattedTotal} {scopes.co2eUnitSymbol}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={3.5}>
          <Box
            mt={0}
            maxWidth="144px"
            ml="auto"
          >
            <img style={{ width: "100%"}} src={earthUrl} alt="" />
          </Box>
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} mb={3}>
        {scopes.entries.map( entry => (
          <Grid item xs={4} key={entry.scope}>
            <Box
              bgcolor={`chart.scope${entry.scope}.100`}
              borderRadius="8px"
              height="100%"
              p={1}
            >
              <Typography
                variant="body1"
                fontWeight={500}
                color="text.dark"
                mb={1}
              >
                {entry.name}
              </Typography>

              <Typography
                variant="h2"
                component="p"
                color="text.dark"
                display="flex"
                flexWrap="wrap"
              >
                {entry.formattedCe}&nbsp;

                <span style={{whiteSpace: "nowrap"}}>
                  {scopes.co2eUnitSymbol}
                </span>
              </Typography>
            </Box>
          </Grid>  
        ))}
      </Grid>

      <Box display="flex">
        <Typography
          variant="body1"
          fontWeight={500}
          mr={1}
        >
          {t("widgets:summaryTile:footerText")}
        </Typography>

        <StyledLink to={RouteConfig.SCENARIOS.fullPath.replace("/:scenarioId?", "")}>
          <Typography
            variant="body1"
            fontWeight={500}
          >
            {t("widgets:goToScenarios")}
          </Typography>
        </StyledLink>
      </Box>
    </StyledPaper>
  )
}