import type { TFunction } from "i18next";
import * as yup from "yup"

import type { InvitationTokenFormState } from "./TokenValidationForm.component";

export const TokenValidationSchema = (translate: TFunction): yup.ObjectSchema<InvitationTokenFormState> => (
  yup.object().shape({
    token: yup
      .string()
      .required(translate("errors:validation:requiredField")),
  })
)