import type { RouteConfig } from "../../api/rest/api"

export const getContactUsFaqsConfig: RouteConfig = {
  method: "GET",
  url: "/api/Faqs/ContactUs",
  // token: required
}

export const getFaqsConfig: RouteConfig = {
  method: "GET",
  url: "/api/Faqs/Help",
  // token: required
}