import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Box, Dialog, Typography } from "@mui/material";

import { useAppDispatch } from "../../../hooks/storeHooks";
import { setDisplayFinishGuideModal } from "../../../store/guide/guide.slice";
import { RouteConfig } from "../../App/Routes/routes.config";
import { Button } from "../../common/Button/Button.component";
import SvgIcon from "../../common/SvgIcon/SvgIcon.component";

export type GuideFinishModalProps = {
  open: boolean
  onClose: () => void
}

export const GuideFinishModal: FC<GuideFinishModalProps> = ({ onClose, open }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const handleCloseModal = () => {
    dispatch(setDisplayFinishGuideModal(false))
    onClose()
  }

  return (
    <Dialog
      onClose={handleCloseModal}
      open={open}
      fullWidth
      maxWidth="xs"
      keepMounted={false}
      disablePortal={false}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        mb={0.5}
      >
        <Typography
          variant="h1"
        >
          {t("guide:finishTourTitle")}
        </Typography>
        <Button
          variant="text"
          color="secondary"
          startIcon={<SvgIcon name="close" />}
          onClick={handleCloseModal}
        />
      </Box>
      <Typography
        variant="body1"
        mb={3}
      >
        {t("guide:finishTourText")}
      </Typography>

      <Box>
        <Button
          size="small"
          component={NavLink}
          to={RouteConfig.DASHBOARD.fullPath}
          onClick={handleCloseModal}
        >
          {t("guide:backToOverview")}
        </Button>
        <Button
          size="small"
          color="secondary"
          sx={{ ml: 1 }}
          onClick={handleCloseModal}
        >
          {t("guide:stayHere")}
        </Button>
      </Box>
    </Dialog>
  );
}