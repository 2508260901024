import type { FC} from "react";
import { useEffect } from "react";
import { Box, Paper, Typography } from "@mui/material";

import { useAppSelector } from "../../../hooks/storeHooks";
import { selectLanguageId } from "../../../store/auth/auth.selectors";
import { Loader } from "../../../components/common/Loader/Loader.component";
import { Select } from "../../../components/common/Select/Select.component";
import { NiceHTML } from "../../../components/NiceHTML";

import { POLICY_ID, useTermsAndConditions } from "./TermsAndConditions.hooks";

export const TermsAndConditionsPage: FC = () => {
  const userLanguageId = useAppSelector(selectLanguageId)
  const {
    languagesLoading,
    parsedLanguageOptions,
    handleChangePolicyLanguage,
    getPolicyByCode,
    policyData,
  } = useTermsAndConditions()

  useEffect(() => {
    if(userLanguageId) {
      getPolicyByCode({ code: POLICY_ID, languageId: userLanguageId })
    }
  }, [userLanguageId])

  if(languagesLoading) {
    return <Loader />
  }
  
  return (
    <Box mt={3}>
      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}
      >
        <Typography
          variant="h1"
          mr={4}
        >
          {policyData?.title}
        </Typography>
        <Box
          minWidth={100}
          ml="auto"
        >
          <Select
            options={parsedLanguageOptions ?? []}
            value={userLanguageId}
            onChange={handleChangePolicyLanguage}
          />
        </Box>
      </Box>
      <Paper>
        {
          policyData
            ? <NiceHTML dangerouslySetInnerHTML={{__html: policyData.text}} />
            : <Loader />
        }
      </Paper>
    </Box>
  )
}
