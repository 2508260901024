import type { FC } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";

import { PageWrapper } from "../../layouts/PageWrapper";
import { GuidesTile } from "../../components/widgets/GuidesTile/GuidesTile.component";
import { ContactTile } from "./ContactTile/ContactTile.component";
import { DictionaryTile } from "./DictionaryTile/DictionaryTile.component";
import { FaqTile } from "./FaqTile/FaqTile.component";

export const KnowledgeBasePage: FC = () => {
  const theme = useTheme()
  const isXlUp = useMediaQuery(theme.breakpoints.up("xl"))

  return (
    <PageWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12} xl>
          <FaqTile />
        </Grid>
  
        <Grid item xs={12} xl="auto">
          <Grid
            container item
            rowSpacing={3}
            maxWidth={isXlUp ? "300px" : "unset"}
            columnSpacing={isXlUp ? 0 : 3}
          >
            <Grid item xs={6} xl={12}>
              <ContactTile />
            </Grid>
              
            <Grid item xs={6} xl={12}>
              <GuidesTile paperProps={{ sx: { minHeight: "unset" } }} />
            </Grid>
  
            <Grid item xs={6} xl={12}>
              <DictionaryTile />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageWrapper>
  )
}
