import { useMemo } from "react";
import type { TFunction } from "i18next";

import { useAppSelector } from "../../../hooks/storeHooks";
import { selectUserData } from "../../../store/auth/auth.selectors";
import { RouteConfig } from "../../App/Routes/routes.config";
import type { MenuItemsConfigType } from "./MenuList.types";

export const useMenuConfig = (t: TFunction): MenuItemsConfigType[] => {
  const userData = useAppSelector(selectUserData)
  const shouldDisplay = userData.roleName === "Admin"

  const menuItems = useMemo(() => [
    {
      text: t("common:menu:overview"),
      iconName: "home",
      navigateTo: RouteConfig.DASHBOARD.fullPath,
      disabled: false,
      visible: true
    },
    {
      text: t("common:menu:emissionReport"),
      iconName: "pie-chart",
      navigateTo: RouteConfig.EXPLORER.fullPath,
      disabled: false,
      visible: true
    },
    {
      text: t("common:menu:inputData"),
      iconName: "map",
      navigateTo: RouteConfig.LOCATIONS.fullPath.replace("/:locationId?", ""),
      disabled: false,
      visible: true
    },
    {
      text: t("common:menu:valueChainEmissions"),
      iconName: "chain",
      navigateTo: RouteConfig.VALUE_CHAIN.fullPath.replace("/:inputFormId?", ""),
      disabled: false,
      visible: true
    },
    {
      text: t("common:menu:reports"),
      iconName: "file",
      navigateTo: RouteConfig.REPORTS.fullPath.replace("/:reportId?", ""),
      disabled: false,
      visible: true
    },
    {
      text: t("common:menu:scenarios"),
      iconName: "tools",
      navigateTo: RouteConfig.SCENARIOS.fullPath.replace("/:scenarioId?", ""),
      disabled: false,
      visible: true
    },
    {
      text: t("common:menu:offsettingMarketplace"),
      iconName: "company",
      navigateTo: "/*",
      disabled: true,
      visible: true
    },
    {
      text: t("common:menu:sustainabilityMarketplace"),
      iconName: "coins",
      navigateTo: "/*",
      disabled: true,
      visible: true
    },
    {
      text: t("common:menu:knowledgeBase"),
      iconName: "knowledge",
      navigateTo: RouteConfig.KNOWLEDGE_BASE.fullPath,
      disabled: false,
      visible: true
    },
    {
      text: t("common:menu:settings"),
      iconName: "settings",
      navigateTo: RouteConfig.SETTINGS.fullPath,
      disabled: false,
      visible: shouldDisplay
    },
  ], [t])
  
  return menuItems
}
