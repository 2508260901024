import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import { RouteConfig } from "../../App/Routes/routes.config";
import { Button } from "../../common/Button/Button.component";
import SvgIcon from "../../common/SvgIcon/SvgIcon.component";
import { StyledCoverWrapper } from "./NoDataCover.styles";

export const NoDataCover: FC = () => {
  const { t } = useTranslation()

  return (
    <StyledCoverWrapper>
      <Box mb={1.5}>
        <SvgIcon name="noDataChart" />
      </Box>

      <Typography
        variant="h2"
        component="h3"
        color="text.dark"
        mb={1}
      >
        {t("widgets:noDataInfo")}
      </Typography>

      <Typography
        variant="body1"
        color="text.secondary"
        mb={2}
      >
        {t("widgets:addDataInfo")}
      </Typography>

      <Button
        variant="outlined"
        size="tiny"
        component={NavLink}
        to={RouteConfig.LOCATIONS.fullPath.replace("/:locationId?", "")}
      >
        {t("common:addData")}
      </Button>
    </StyledCoverWrapper>
  )
}