import type { FC } from "react"
import { useTranslation } from "react-i18next"
import { Accordion, AccordionDetails, AccordionSummary, Box, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import dayjs from "dayjs"

import { useRestoreLocationMutation } from "../../../../store/locations/locations.api";
import SvgIcon from "../../../../components/common/SvgIcon/SvgIcon.component";
import { getLocationAddress } from "../../../../components/LocationsModal/LocationForms/LocationForms.utils";
import type { LocationResponseModel } from "../../../../store/locations/locations.types";
import { StyledButton, StyledTableCell, StyledTableHeadCell } from "./LocationsTableArchived.styles";

export type LocationsTableArchivedProps = {
  locations: LocationResponseModel[]
}

export const LocationsTableArchived: FC<LocationsTableArchivedProps> = ({ locations }) => {
  const { t } = useTranslation()
  const [restoreLocation] = useRestoreLocationMutation()

  const handleRestoreLocation = async (locationId: number) => {
    try {
      await restoreLocation({ locationId }).unwrap()
    } catch(e) {
      // TODO: handle error
    }
  }
  
  return (
    <Box>
      <Accordion disableGutters sx={{ p: 0 }}>
        <AccordionSummary
          sx={{ p: 0, mb: 2 }}
          expandIcon={<SvgIcon name="chevron-down" />}
        >
          <Typography
            variant="body1"
            fontWeight={500}
            color="text.secondary"
          >
            {t("locations:archive")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableHeadCell>
                    {t("locations:tableHeadName")}
                  </StyledTableHeadCell>
                  <StyledTableHeadCell>
                    {t("locations:tableHeadAddress")}
                  </StyledTableHeadCell>
                  <StyledTableHeadCell>
                    {t("locations:tableHeadClosingDate")}
                  </StyledTableHeadCell>

                  <StyledTableHeadCell />
                </TableRow>
              </TableHead>
        
              <TableBody>
                {locations.length ? locations.map((location) => (
                  <TableRow key={location.locationId}>
                    <StyledTableCell component="th" scope="row">
                      <Typography>
                        {location.name}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell sx={{ width: "100%" }}>
                      <Typography>
                        {getLocationAddress(location)}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell>
                      <Typography>
                        {location.dateDisabled
                          ? dayjs(location.dateDisabled).format("DD.MM.YYYY")
                          : "-"
                        }
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell>
                      <Box>
                        <StyledButton
                          variant="text"
                          size="tiny"
                          onClick={async () => handleRestoreLocation(location.locationId)}
                          sx={{ mr: 4 }}
                        >
                          {t("common:restore")}
                        </StyledButton>
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                )) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
