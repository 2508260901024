import type { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import { useYupResolver } from "../../../../hooks/useYupResolver";
import { usePasswordRequestCodeMutation } from "../../../../store/users/users.api";
import { RouteConfig } from "../../../../components/App/Routes/routes.config";
import { Button } from "../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import SvgIcon from "../../../../components/common/SvgIcon/SvgIcon.component";
import { TextFieldController } from "../../../../components/common/TextFieldController/TextFieldController.component";
import getErrorFromApiResponse from "../../../../utils/getErrorFromApiResponse";
import type { ForgotPasswordEmailFormProps, ForgotPasswordEmailFormState } from "./ForgotPasswordEmailForm.types";
import { StyledButton } from "./ForgotPasswordEmailForm.styles";

import { ForgotPasswordEmailFormValidationSchema } from "./ForgotPasswordEmailForm.validation";

export const ForgotPasswordEmailForm: FC<ForgotPasswordEmailFormProps> = ({ onSuccess }) => {
  const { t } = useTranslation()
  const [ passwordRequestCode, { isLoading } ] = usePasswordRequestCodeMutation()

  const form = useForm<ForgotPasswordEmailFormState>({
    defaultValues: {
      email: "",
    },
    mode: "onSubmit",
    resolver: useYupResolver(ForgotPasswordEmailFormValidationSchema)
  });

  const handleSubmit = form.handleSubmit(async (values) => {
    try {
      await passwordRequestCode({ email: values.email }).unwrap()
      onSuccess(values.email)
    } catch(err) {
      form.setError("email", {
        message : getErrorFromApiResponse(err, t("errors:globalError"))
      });
    }
  })

  return (
    <FormProvider {...form}>
      <Box
        component='form'
        onSubmit={handleSubmit}
      >
        <Typography
          variant="h1"
          fontSize={32}
          fontWeight={700}
          lineHeight="40px"
          color="text.dark"
          mb={2}
        >
          {t("user:forgotPasswordEmailHeader")}
        </Typography>

        <Typography
          variant="h3"
          component="p"
          color="text.secondary"
          mb={4}
        >
          {t("user:forgotPasswordEmailSubheader")}
        </Typography>

        <TextFieldController
          name="email"
          label={t("form:label:email")}
          placeholder={t("form:placeholder:enterEmail")}
          sx={{ mb: 4 }}
        />

        <Button
          type="submit"
          fullWidth
          size="large"
          endIcon={isLoading ? <ButtonLoader /> : null}
          disabled={isLoading}
        >
          {t("user:forgotPasswordEmailSubmit")}
        </Button>

        <StyledButton
          component={NavLink}
          to={RouteConfig.LOGIN.fullPath}
          fullWidth
          size="large"
          variant="text"
          color="secondary"
          disabled={isLoading}
          startIcon={<SvgIcon name="back" />}
        >
          {t("user:forgotPasswordBackToLogin")}
        </StyledButton>
      </Box>
    </FormProvider>
  )
}
