import type { FC} from "react";
import { useState } from "react";
import { useController } from "react-hook-form"
import type { BoxProps } from "@mui/material";
import { Box , Checkbox, FormControlLabel, FormHelperText, Typography } from "@mui/material";

import SvgIcon from "../SvgIcon/SvgIcon.component";
import { InfoLabel } from "../TextField/InfoLabel/InfoLabel.component";
import { Tooltip } from "../Tooltip/Tooltip.component";
import type { CheckboxControllerProps } from "./CheckboxController.types";

export const CheckboxController: FC<BoxProps & CheckboxControllerProps> = ({
  label,
  name,
  required = false,
  disabled,
  labelProps,
  tooltipText,
  ...props
}) => {
  const [iconName, setIconName] = useState<"qm-filled" | "qm-stroke">("qm-stroke")
  const labelType = required ? `${label} *` : label
  const { field, fieldState: { error, invalid } } = useController({ name })

  return (
    <>
      <FormControlLabel
        label={tooltipText ? (
          <Tooltip
            title={tooltipText}
            placement="top-start"
            color={disabled ? "disabled" : "dark"}
          >
            <Box
              display="flex"
              alignItems="center"
              onMouseEnter={() => setIconName("qm-filled")}
              onMouseLeave={() => setIconName("qm-stroke")}
            >
              {labelType}
              <SvgIcon
                name={iconName}
                color="grey.400"
                sx={{ ml: 0.5 }}
              />
            </Box>
          </Tooltip>
        ) : (
          <Typography
            variant="body1"
            {...labelProps}
          >
            {labelType}
          </Typography>
        )
        }
        componentsProps={{ typography: { width: "100%" } }}
        disabled={disabled}
        {...props}
        control={
          <Checkbox
            checked={field.value}
            onChange={field.onChange}
          />
        }
      />

      <FormHelperText>
        <InfoLabel invalid={invalid}>
          { error?.message }
        </InfoLabel>
      </FormHelperText>
    </>
  )
}
