import type { FC, ReactNode} from "react";
import type { BoxProps} from "@mui/material";
import { Box, Typography } from "@mui/material";

type PageWrapperProps = BoxProps & {
  title?: string
  children: ReactNode
}

export const PageWrapper: FC<PageWrapperProps> = ({ title, children, ...props }) => (
  <Box
    mt={1.5}
    mb={3}
    display="flex"
    flexDirection="column"
    width="100%"
    flex={1}
    {...props}
  >
    {title ? <Typography
      variant="h1"
      color="text.dark"
      mb={2}
    >
      {title}
    </Typography> : null}
    
    {children}
  </Box>
)