import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import type { AxiosResponse } from "axios";

import { useYupResolver } from "../../../../hooks/useYupResolver";
import { useValidateRegistrationTokenMutation } from "../../../../store/registrations/registrations.api";
import { Button } from "../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import { TextFieldController } from "../../../../components/common/TextFieldController/TextFieldController.component";
import type { ApiErrorResponseModel } from "../../../../store/store.types";

import { TokenValidationSchema } from "./TokenValidationForm.validation";

export type InvitationTokenFormState = {
  token: string
}

export const TokenValidationForm = () => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [validateToken, { isLoading }] = useValidateRegistrationTokenMutation()

  const form = useForm<InvitationTokenFormState>({
    defaultValues: {
      token: "",
    },
    mode: "onSubmit",
    resolver: useYupResolver(TokenValidationSchema),
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    const { token } = values

    try {
      await validateToken({ token }).unwrap()

      searchParams.set("token", token)
      setSearchParams(searchParams)
    } catch (e) {
      const err = e as AxiosResponse<ApiErrorResponseModel>
      const isInvalid = err.data.isValid === false
      const errorDetail = err.data.detail

      if (errorDetail) {
        form.setError("token", {
          message: errorDetail
        });
      } else if (isInvalid) {
        form.setError("token", {
          message: t("errors:validation:invalidToken")
        });
      } else {
        form.setError("token", {
          message: t("errors:globalError")
        });
      }
    }
  })

  return (
    <>
      <Typography
        variant="body1"
        fontSize={16}
        lineHeight="24px"
        color="text.secondary"
        mb={4}
      >
        {t("user:invitationPage:token:info")}
      </Typography>

      <FormProvider {...form}>
        <Box
          component="form"
          onSubmit={handleSubmit}
        >
          <TextFieldController
            name="token"
            label={t("user:invitationPage:token:inputLabel")}
            placeholder={t("user:invitationPage:token:inputPlaceholder")}
            autoFocus
            sx={{ mb: 3 }}
            disabled={isLoading}
          />

          <Button
            type="submit"
            fullWidth
            size="large"
            endIcon={isLoading ? <ButtonLoader /> : null}
            disabled={isLoading}
          >
            {t("user:invitationPage:token:submit")}
          </Button>
        </Box>
      </FormProvider>
    </>
  )
}
