import type { RouteConfig } from "../../api/rest/api";

// Validate registration token.
export const validateRegistrationTokenConfig: RouteConfig = {
  method: "POST",
  url: "/api/Registrations/Tokens/Validate",
  // token: not required
}

// Register a new company using a registration token.
export const postNewCompanyConfig: RouteConfig = {
  method: "POST",
  url: "/api/Registrations",
  // token: not required
}