import { colors } from "../colors"

export const palette = {
  text: {
    primary: colors.textPrimary,
    secondary: colors.textSecondary,
    dark: colors.textDark,
    light: colors.textLight,
  },
  background: {
    default: colors.grey200,
    appBar: colors.grey100,
    paper: colors.white
  },
  primary: {
    abcd: colors.green600,
    100: colors.primary100,
    200: colors.primary200,
    300: colors.primary300,
    main: colors.primary500,
    700: colors.primary700,
    900: colors.primary900,
  },
  error: {
    main: colors.errorMain,
    light: colors.errorLight,
    dark: colors.errorDark,
    contrastText: "rgba(0, 0, 0, 1)"
  },
  warning: {
    main: colors.warningMain,
    light: colors.warningLight,
    dark: colors.warningDark,
  },
  success: {
    main: colors.successMain,
    light: colors.successLight,
    dark: colors.successDark,
  },
  info: {
    main: colors.infoMain,
    light: colors.infoLight,
    dark: colors.infoDark,
  },
  grey: {
    100: colors.grey100,
    200: colors.grey200,
    300: colors.grey300,
    400: colors.grey400,
    500: colors.grey500,
    700: colors.grey700,
    900: colors.grey900,
    main: colors.grey500,
    contrastText: "rgba(0, 0, 0, 1)"
  },
  chart: {
    fallbackGreen600: colors.green600,
    fallbackBlue600: colors.blue600,
    fallbackTurquoise600: colors.turquoise600,
    fallbackYellow600: colors.yellow600,
    fallbackGreengray600: colors.greengray600,
    fallbackGreyblue600: colors.greyblue600,

    fallbackGreen400: colors.green400,
    fallbackBlue400: colors.blue400,
    fallbackTurquoise400: colors.turquoise400,
    fallbackYellow400: colors.yellow400,
    fallbackGreengray400: colors.greengray400,
    fallbackGreyblue400: colors.greyblue400,

    fallbackGreen200: colors.green200,
    fallbackBlue200: colors.blue200,
    fallbackTurquoise200: colors.turquoise200,
    fallbackYellow200: colors.yellow200,
    fallbackGreengray200: colors.greengray200,
    fallbackGreyblue200: colors.greyblue200,

    fallbackGrey900: colors.grey900,
    fallbackGrey700: colors.grey700,
    fallbackGrey500: colors.grey500,
    fallbackGrey400: colors.grey400,
    fallbackGrey300: colors.grey300,
    fallbackGrey200: colors.grey200,

    scope1: {
      100: colors.scope1100,
      200: colors.scope1200,
      300: colors.scope1300,
      500: colors.scope1500,
      700: colors.scope1700,
      900: colors.scope1900,
    },
    scope2: {
      100: colors.scope2100,
      200: colors.scope2200,
      300: colors.scope2300,
      500: colors.scope2500,
      700: colors.scope2700,
      900: colors.scope2900,
    },
    scope3: {
      100: colors.scope3100,
      200: colors.scope3200,
      300: colors.scope3300,
      500: colors.scope3500,
      700: colors.scope3700,
      900: colors.scope3900,
    },
  }
}