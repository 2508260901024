import type { FC} from "react";
import { useMemo } from "react";
import { Grid } from "@mui/material";

import { PageWrapper } from "../../layouts/PageWrapper";
import { useGetAllLocationsQuery } from "../../store/locations/locations.api";
import { useGetWidgetSelectionsQuery } from "../../store/widgetSelections/widgetSelections.api";
import { Loader } from "../../components/common/Loader/Loader.component";
import { OverviewHeader } from "./OverviewHeader/OverviewHeader.component";
import { OverviewIntro } from "./OverviewIntro/OverviewIntro.component";
import { renderWidget } from "./OverviewPage.utils";

export const OverviewPage: FC = () => {
  const {
    data: locations,
    isFetching: locationsFetching
  } = useGetAllLocationsQuery({ addOptionAll: true }, { refetchOnMountOrArgChange: true })

  const {
    data: widgetSelections,
    isFetching: widgetSelectionsLoading,
  } = useGetWidgetSelectionsQuery()

  const widgets = useMemo(() => widgetSelections?.filter(widget => widget.show).sort((a, b) => a.displayOrder - b.displayOrder), [widgetSelections])

  if(locationsFetching) {
    return <Loader />
  }

  return (
    <PageWrapper>
      <OverviewHeader />

      {locations?.length 
        ? widgetSelectionsLoading 
          ? <Loader /> 
          : (
            <Grid container spacing={3}>
              {widgets?.map( widget => renderWidget(widget))}
            </Grid>
          )
        : <OverviewIntro />
      }
    </PageWrapper>
  )
}