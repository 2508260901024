import { type FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import type { AxiosResponse } from "axios";

import { useYupResolver } from "../../../../hooks/useYupResolver";
import { usePasswordResetMutation } from "../../../../store/users/users.api";
import { RouteConfig } from "../../../../components/App/Routes/routes.config";
import { Button } from "../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import SvgIcon from "../../../../components/common/SvgIcon/SvgIcon.component";
import { TextFieldController } from "../../../../components/common/TextFieldController/TextFieldController.component";
import type { ApiErrorResponseModel } from "../../../../store/store.types";
import type { ForgotPasswordNewPasswordFormProps, ForgotPasswordNewPasswordFormState } from "./ForgotPasswordNewPasswordForm.types";
import { StyledButton } from "./ForgotPasswordNewPasswordForm.styles";

import { ForgotPasswordNewPasswordFormValidationSchema } from "./ForgotPasswordNewPasswordForm.validation";

export const ForgotPasswordNewPasswordForm: FC<ForgotPasswordNewPasswordFormProps> = ({ email, code, onSuccess }) => {
  const { t } = useTranslation()
  const [ passwordReset, { isLoading } ] = usePasswordResetMutation()

  const form = useForm<ForgotPasswordNewPasswordFormState>({
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
    mode: "all",
    resolver: useYupResolver(ForgotPasswordNewPasswordFormValidationSchema)
  });

  const handleSubmit = form.handleSubmit(async (values) => {
    try {
      await passwordReset({
        email,
        code,
        newPassword: values.confirmPassword
      }).unwrap()
      onSuccess()
    } catch(e) {
      const err = e as AxiosResponse<ApiErrorResponseModel>
      const errorDetail = err.data.detail
      
      form.setError("confirmPassword", {
        message : errorDetail ?? t("errors:globalError")
      });
    }
  })

  return (
    <FormProvider {...form}>
      <Box
        component='form'
        onSubmit={handleSubmit}
      >
        <Typography
          variant="h1"
          fontSize={32}
          fontWeight={700}
          lineHeight="40px"
          color="text.dark"
          mb={2}
        >
          {t("user:forgotPasswordNewPasswordHeader")}
        </Typography>

        <Typography
          variant="h3"
          component="p"
          fontWeight={400}
          color="text.secondary"
          mb={4}
        >
          {t("user:forgotPasswordNewPasswordSubheader")}
        </Typography>

        <TextFieldController
          type="password"
          name="newPassword"
          label={t("form:label:password")}
          placeholder={t("form:label:password")}
          sx={{ mb: 4 }}
          disabled={isLoading}
          helperText={t("user:forgotPasswordNewPasswordInfo")}
        />

        <TextFieldController
          type="password"
          name="confirmPassword"
          label={t("form:label:confirmNewPassword")}
          placeholder={t("form:placeholder:confirmNewPassword")}
          sx={{ mb: 4 }}
          disabled={isLoading}
        />

        <Button
          type="submit"
          fullWidth
          size="large"
          endIcon={isLoading ? <ButtonLoader /> : null}
          disabled={isLoading}
          sx={{ mb: 3 }}
        >
          {t("user:forgotPasswordNewPasswordSubmit")}
        </Button>

        <StyledButton
          component={NavLink}
          to={RouteConfig.LOGIN.fullPath}
          fullWidth
          size="large"
          variant="text"
          color="secondary"
          disabled={isLoading}
          startIcon={<SvgIcon name="back" />}
        >
          {t("user:forgotPasswordBackToLogin")}
        </StyledButton>
      </Box>
    </FormProvider>
  )
}
