import { apiService } from "../store.service";
import type { RegistrationPostModel, RegistrationTokenValidationPostModel, RegistrationTokenValidationResponseModel } from "./registrations.types";

import { postNewCompanyConfig, validateRegistrationTokenConfig } from "./registrations.config";

export const RegistrationsApi = apiService.injectEndpoints({
  endpoints: build => ({
    validateRegistrationToken: build.mutation<RegistrationTokenValidationResponseModel, RegistrationTokenValidationPostModel>({
      query: ({ token }) => ({
        ...validateRegistrationTokenConfig,
        data: {
          token
        }
      }),
    }),
    postNewCompany: build.mutation<void, RegistrationPostModel>({
      query: (data) => ({
        ...postNewCompanyConfig,
        data
      }),
    }),
  }),
});

export const {
  useValidateRegistrationTokenMutation,
  usePostNewCompanyMutation,
} = RegistrationsApi