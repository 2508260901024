import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { RouteConfig } from "../../App/Routes/routes.config";
import type { UserMenuItemsConfigType } from "./UserMenu.types";

export const useUserMenuConfig = (handleCloseMenu: () => void): UserMenuItemsConfigType[] => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()

  const goToProfileSettings = () => {
    handleCloseMenu()
    navigate(RouteConfig.PROFILE.fullPath)
  }

  return [
    {
      text: i18n.t("user:menu:profileSettings"),
      iconName: "settings",
      onClick: goToProfileSettings,
      disabled: false,
      visible: true
    },
  ]
}