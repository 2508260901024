import type { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Box, TableCell, Typography } from "@mui/material";
import dayjs from "dayjs";

import { useDeleteScope3ConsumptionConfigMutation } from "../../../../../store/scope3Consumptions/scope3Consumptions.api";
import { Button } from "../../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import SvgIcon from "../../../../../components/common/SvgIcon/SvgIcon.component";
import { Scope3TableCellTooltip } from "./Scope3TableCellTooltip/Scope3TableCellTooltip.component";
import type { ProcessedRowItem, RowItem } from "../../../../../store/scope3Consumptions/scope3Consumptions.types";
import { InputFormFieldType } from "../../../Scope3FormTab/Scope3Form/Scope3Form.types";

export type Scope3TableCellsProps = {
  rowData: ProcessedRowItem[]
  columnsNames: string[]
  setItemToEdit: Dispatch<SetStateAction<RowItem[] | null>>
}

export const Scope3TableCells: FC<Scope3TableCellsProps> = ({ rowData, setItemToEdit, columnsNames }) => {
  const { t } = useTranslation()
  const scope3ConsumptionEntryId = rowData.find(cell => cell.name === "scope3ConsumptionEntryId")?.id
  const filteredCells = rowData.filter(cell => columnsNames.includes(cell.name))

  const [deleteScope3Consumption, { isLoading }] = useDeleteScope3ConsumptionConfigMutation()

  const handleDeleteConsumption = async () => {
    if(scope3ConsumptionEntryId) {
      await deleteScope3Consumption({ consumptionId: scope3ConsumptionEntryId })
    }
  }

  const processValue = (cell: ProcessedRowItem) => {
    switch(cell.type) {
      case InputFormFieldType.DROPDOWN:
      case InputFormFieldType.TEXT_FIELD:
        return cell.value
      case InputFormFieldType.DECIMAL:
        return Number(cell.value).toFixed(3)
      case InputFormFieldType.DATE_PICKER:
        return dayjs(cell.value).format("DD MMM YYYY")
      case InputFormFieldType.CHECKBOX:
        return cell.value ? t("common:yes") : t("common:no")
      default:
        return cell.value
    }
  }

  return (
    <>
      {filteredCells.map(cell => (
        <TableCell key={cell.name} component="td" scope="row">
          <Typography variant="body1">
            {processValue(cell)}&nbsp;
            {cell.child ? processValue(cell.child) : null}
            {cell.tooltipData ? <Scope3TableCellTooltip url={cell.tooltipData} /> : null}
          </Typography>
        </TableCell>
      ))}

      <TableCell component="td" scope="row" width="100px">
        <Box>
          <Button
            variant="outlined"
            color="secondary"
            size="tiny"
            startIcon={<SvgIcon name="edit" />}
            onClick={() => setItemToEdit(rowData)}
          />
          <Button
            variant="outlined"
            color="secondary"
            size="tiny"
            startIcon={isLoading ? <ButtonLoader /> : <SvgIcon name="trash" />}
            onClick={handleDeleteConsumption}
            sx={{ ml: 1.5 }}
          />
        </Box>
      </TableCell>
    </>
  )
}
