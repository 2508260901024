import type { PaletteColor } from "@mui/material";

import { theme } from "../theme/theme";

export const getFallbackColors = (chart: PaletteColor, includedString = "fallback"): string[] => 
  (Object.keys(chart) as (keyof PaletteColor)[])
    .filter(key => typeof key === "string" && key.includes(includedString))
    .map(key => chart[key] as string)

export const createFallbackColorGenerator = (colors: string[]) => {
  let availableColors = [...colors];
    
  return () => {
    if (availableColors.length === 0) {
      availableColors = [...colors];
    }
    return availableColors.shift() ?? theme.palette.grey[500];
  };
};