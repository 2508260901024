import type { RefObject } from "react";
import type { TFunction } from "i18next";
import * as yup from "yup"

import type { Scope3FieldChildResponseModel, Scope3FieldExtendedResponseModel } from "../../../../store/scope3Fields/scope3Fields.types";
import { InputFormFieldType } from "./Scope3Form.types";

type SchemaType = {
  fields: {
    [fieldKey: string]: string
  }
}

export const createValidationSchema = (
  t: TFunction,
  inputFormFields: Scope3FieldExtendedResponseModel[],
  formRef: RefObject<HTMLFormElement>
) => {
  const validationSchema: { [key: string]: yup.Schema } = {};

  const setSchemaForField = (field: Scope3FieldChildResponseModel | Scope3FieldExtendedResponseModel) => {
    switch (field.fieldType) {
      case InputFormFieldType.DROPDOWN:
        if (field.name === "Type") {
          validationSchema[field.name] = yup
            .object({
              label: yup.string().required(),
              value: yup.string().required()
            })
            .required(t("errors:validation:requiredField"))
        } else {
          validationSchema[field.name] = yup
            .object({
              label: yup.string().required(),
              value: yup.string().required()
            })
            .required(t("errors:validation:requiredField"))
        }
        break
      case InputFormFieldType.DATE_PICKER:
      case InputFormFieldType.DECIMAL:
      case InputFormFieldType.TEXT_FIELD:
        validationSchema[field.name] = yup.string().required(t("errors:validation:requiredField"));
        break;
      case InputFormFieldType.CHECKBOX:
        break
      default:
        break
    }
  }

  inputFormFields.forEach((field) => {
    if (!field.isRequired) return
    setSchemaForField(field)

    if (field.child) {
      setSchemaForField(field.child)
    }
  });

  return yup.object().shape(validationSchema).required().when((values: unknown, schema: SchemaType) => {
    for (const key in schema.fields) {
      const currentField = formRef.current.querySelector(`#${key}`)
  
      if (currentField === null) {
        delete schema.fields[key]
      }
    }
  });
};