import {useState} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Paper, Typography } from "@mui/material";

import EarthImageUrl from "../../../assets/img/welcomeEarth.png"
import { RouteConfig } from "../../../components/App/Routes/routes.config";
import { Button } from "../../../components/common/Button/Button.component";
import SvgIcon from "../../../components/common/SvgIcon/SvgIcon.component";
import { NewLocationModal } from "../../../components/NewLocationModal/NewLocationModal.component";
import { StyledTypography } from "./OverviewIntro.styles";

export const OverviewIntro = () => {
  const { t } = useTranslation()
  const [displayNewLocationForm, setDisplayNewLocationForm] = useState(false);
  const navigate = useNavigate()
  
  const handleCloseNewLocationModal = () => {
    setDisplayNewLocationForm(false)
    navigate(RouteConfig.LOCATIONS.fullPath.replace("/:locationId?", ""))
  }

  return (
    <Paper sx={{ p: 0 }}>
      <Grid container>
        <Grid item xs p={3}>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
          >
            <Typography
              variant="h2"
              color="text.dark"
              mb={2}
            >
              {t("locations:addLocation")}
            </Typography>

            <Typography
              variant="body1"
              color="text.secondary"
            >
              {t("guide:overviewIntroText")}
            </Typography>

            <Box mt="auto">
              <Button
                onClick={() => setDisplayNewLocationForm(true)}
                sx={{
                  position: "relative",
                  mb: 3,
                }}
              >
                {t("locations:addNewLocation")}

                <Box
                  position="absolute"
                  top="30%"
                  left="115%"
                >
                  <SvgIcon name="onboardingArrow" />
                  <StyledTypography>
                    {t("guide:startHere")}
                  </StyledTypography>

                </Box>
              </Button>

            </Box>
          </Box>
        </Grid>
        <Grid item xs="auto">
          <Box sx={{
            mr: 3,
            maxWidth: "271px",
            "& img": { width: "100%" }
          }}>
            <img src={EarthImageUrl} alt="earth" />
          </Box>
        </Grid>
      </Grid>

      <NewLocationModal
        open={displayNewLocationForm}
        onClose={handleCloseNewLocationModal}
      />
    </Paper>
  );
}
