import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../../../../hooks/storeHooks";
import { useGetAnswersByIdQuery } from "../../../../../../../store/reportQuestionnaireAnswers/reportQuestionnaireAnswers.api";
import { selectStepperSteps } from "../../../../../../../store/reports/reports.selectors";
import { setStep } from "../../../../../../../store/reports/reports.slice";
import { Loader } from "../../../../../../../components/common/Loader/Loader.component";
import { setErrorIcon } from "../ReviewStep.utils";
import type { ReportHeaderValidationResponseModel } from "../../../../../../../store/reportHeaders/reportHeaders.types";
import type { ReportQuestionnaireAnswerResponseModel } from "../../../../../../../store/reportQuestionnaireAnswers/reportQuestionnaireAnswers.types";
import { CreateReportSteps } from "../../../FormStepper.types";
import { StyledButton, StyledTile } from "../ReviewStep.styles";

export type GeneralInformationTileProps = {
  headerId: number
  validatedAnswers?: { [key: string]: ReportHeaderValidationResponseModel }
}

export const GeneralInformationTile: FC<GeneralInformationTileProps> = ({ headerId, validatedAnswers }) => {
  const { t } = useTranslation()
  const { data, isLoading } = useGetAnswersByIdQuery({ headerId })
  const steps = useAppSelector(selectStepperSteps)
  const dispatch = useAppDispatch()

  const goToGeneralInformationStep = () => {
    const generalInformationStepIndex = steps.findIndex(step => step.component === CreateReportSteps.GENERAL_INFO)
    dispatch(setStep(generalInformationStepIndex))
  }

  const extractCommentsFields = (obj?: ReportQuestionnaireAnswerResponseModel) => {
    const commentsValues: string[] = [];
  
    for (const item in obj) {
      if (item.toLowerCase().includes("comments") && obj[item as keyof ReportQuestionnaireAnswerResponseModel]) {
        commentsValues.push(String(obj[item as keyof ReportQuestionnaireAnswerResponseModel]));
      }
    }
  
    return commentsValues.length ? commentsValues.join(", ") : "-";
  }
  
  const changes = extractCommentsFields(data);

  const changesFieldNames = [
    "investments", "investmentComments",
    "energyChanges", "energyComments",
    "processChanges", "processComments",
    "vehicleChanges", "vehicleComments",
    "logisticsChanges", "logisticsComments",
  ]

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Typography
          variant="h3"
          color="text.dark"
          mb={1}
        >
          {t("reports:reviewStep:generalInformationTile:title")}
        </Typography>

        <StyledButton
          variant="text"
          size="small"
          color="secondary"
          disabled={isLoading}
          onClick={goToGeneralInformationStep}
        >
          {t("common:edit")}
        </StyledButton>
      </Box>

      <StyledTile>
        {isLoading ? <Loader /> : (
          <>
            <Box
              display="flex"
              mt={1.5}
            >
              <Typography variant="body1" minWidth="100px" mr={1.5}>
                {t("reports:reviewStep:generalInformationTile:nameLabel")}&nbsp;
              </Typography>
          
              <Typography
                variant="body1"
                fontWeight={500}
                display="flex"
              >
                {data?.companyName ?? "-"}
                {validatedAnswers ? setErrorIcon("companyName", validatedAnswers) : null}
              </Typography>
            </Box>

            <Box
              display="flex"
              mt={1.5}
            >
              <Typography variant="body1" minWidth="100px" mr={1.5}>
                {t("reports:reviewStep:generalInformationTile:countriesLabel")}&nbsp;
              </Typography>
          
              <Typography
                variant="body1"
                fontWeight={500}
                display="flex"
              >
                {data?.countries.map(country => country.name).join(", ") ?? "-"}
                {validatedAnswers ? setErrorIcon("countries", validatedAnswers) : null}
              </Typography>
            </Box>

            <Box
              display="flex"
              mt={1.5}
            >
              <Typography variant="body1" minWidth="100px" mr={1.5}>
                {t("reports:reviewStep:generalInformationTile:aboutLabel")}&nbsp;
              </Typography>
          
              <Typography
                variant="body1"
                fontWeight={500}
                display="flex"
              >
                {data?.production ?? "-*"}
                {validatedAnswers ? setErrorIcon("production", validatedAnswers) : null}
              </Typography>
            </Box>

            <Box
              display="flex"
              mt={1.5}
            >
              <Typography variant="body1" minWidth="100px" mr={1.5}>
                {t("reports:reviewStep:generalInformationTile:activitiesLabel")}&nbsp;
              </Typography>
          
              <Typography
                variant="body1"
                fontWeight={500}
                display="flex"
              >
                {data?.policies ?? "-*"}
                {validatedAnswers ? setErrorIcon("productionDetails", validatedAnswers) : null}
              </Typography>
            </Box>

            <Box
              display="flex"
              mt={1.5}
            >
              <Typography variant="body1" minWidth="100px" mr={1.5}>
                {t("reports:reviewStep:generalInformationTile:changesLabel")}&nbsp;
              </Typography>
          
              <Typography
                variant="body1"
                fontWeight={500}
                display="flex"
              >
                {changes}
                {validatedAnswers ? setErrorIcon(changesFieldNames, validatedAnswers) : null}
              </Typography>
            </Box>

            <Box
              display="flex"
              mt={1.5}
            >
              <Typography variant="body1" minWidth="100px" mr={1.5}>
                {t("reports:reviewStep:generalInformationTile:employeesLabel")}&nbsp;
              </Typography>
          
              <Typography
                variant="body1"
                fontWeight={500}
                display="flex"
              >
                {data?.employees ?? "-"}
                {validatedAnswers ? setErrorIcon("employees", validatedAnswers) : null}
              </Typography>
            </Box>

            <Box
              display="flex"
              mt={1.5}
            >
              <Typography variant="body1" minWidth="100px" mr={1.5}>
                {t("reports:reviewStep:generalInformationTile:auditNumberLabel")}&nbsp;
              </Typography>
          
              <Typography
                variant="body1"
                fontWeight={500}
                display="flex"
              >
                {data?.number ?? "-"}
                {validatedAnswers ? setErrorIcon(["isFirstAudit", "number"], validatedAnswers) : null}
              </Typography>
            </Box>
          </>
        )}
      </StyledTile>
    </>
  )
}
