
import { Accordion, AccordionDetails, AccordionSummary, Box, ListItem, styled,Typography } from "@mui/material";

export const StyledListItem = styled(ListItem)(({
  padding: 0,
  "& .MuiPaper-root": {
    borderRadius: 0,
    background: "transparent"    
  }
}))

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: "100%",
  padding: 0,
  overflow: "visible",
  transition: `${theme.transitions.duration.standard}ms all`,
  marginBottom: theme.spacing(2),
}))

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  display: "flex",
  margin: 0,
  padding: theme.spacing(1),
  minHeight: "unset",
  borderTopLeftRadius: theme.spacing(2),
  borderTopRightRadius: theme.spacing(2),
  "&.Mui-expanded": {
    minHeight: "unset",
    background: theme.palette.common.white,
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
}))

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0,1,1,1),
  background: theme.palette.common.white,
  borderBottomLeftRadius: theme.spacing(2),
  borderBottomRightRadius: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

export const StyledTypography = styled(
  Typography,
  { shouldForwardProp: prop => prop !== "expanded"}
)<{ expanded: boolean }>(({ theme, expanded }) => ({
  marginLeft: theme.spacing(1),
  "&:hover": {
    color: expanded ? theme.palette.text.primary : theme.palette.primary.main,
  },
}))

export const StyledIndexWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.primary[200],
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 20,
  minWidth: 20,
  height: 20,
}))