import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { PageWrapper } from "../../../layouts/PageWrapper";
import { useGetReportByIdQuery } from "../../../store/reports/reports.api";
import { selectStepperCurrentStep } from "../../../store/reports/reports.selectors";
import { prevStep, setStep } from "../../../store/reports/reports.slice";
import { RouteConfig } from "../../../components/App/Routes/routes.config";
import { Button } from "../../../components/common/Button/Button.component";
import { Loader } from "../../../components/common/Loader/Loader.component";
import SvgIcon from "../../../components/common/SvgIcon/SvgIcon.component";
import { FormStepper } from "./FormStepper/FormStepper.component";
import { CreateReportSteps } from "./FormStepper/FormStepper.types";

import { stepsConfig } from "./FormStepper/FormStepper.config";

export const CreateReportPage: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const currentStep = useAppSelector(selectStepperCurrentStep)
  const { reportId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const editMode = location.state?.editMode as boolean
  
  const { data: report, isLoading } = useGetReportByIdQuery(Number(reportId))
  
  const stepperSteps = stepsConfig(t, report?.questionnaireType)
  const enabledSteps = stepperSteps.filter((step) => step.enabled)
  const reviewStepIndex = enabledSteps.findIndex(step => step.component === CreateReportSteps.REVIEW)
  const lastStep = reviewStepIndex === currentStep
  const reportsPath = RouteConfig.REPORTS.fullPath.replace(":reportId?", reportId ?? "")

  const goToPrevStep = () => {
    dispatch(prevStep())
  };

  const goToReports = () => {
    dispatch(setStep(0))
    navigate(reportsPath, { state: { savedTab: true }})
  }

  const handleCancel = () => {
    dispatch(setStep(0))
    navigate(reportsPath, { state: { savedTab: editMode || currentStep > 0 }})
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <PageWrapper>
      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}
      >
        {currentStep ? (
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={goToPrevStep}
            startIcon={<SvgIcon name="arrowLeft" />}
          >
            {t("common:back")}
          </Button>
        ) : null}

        {lastStep ? (
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={goToReports}
          >
            {t("common:finish")}
          </Button>
        ) : (
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
            sx={{ ml: "auto" }}
          >
            {t("common:cancel")}
          </Button>
        )}
        
      </Box>

      <FormStepper questionnaireType={report?.questionnaireType ?? ""} />
    </PageWrapper>
  )
}
