import type { FC} from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { useAppSelector } from "../../../../../../hooks/storeHooks";
import { selectUserLocale } from "../../../../../../store/auth/auth.selectors";
import { useGetScope3ConsumptionEmissionFactorsQuery } from "../../../../../../store/scope3Consumptions/scope3Consumptions.api";
import { ButtonLoader } from "../../../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import SvgIcon from "../../../../../../components/common/SvgIcon/SvgIcon.component";
import { Tooltip } from "../../../../../../components/common/Tooltip/Tooltip.component";

export type Scope3TableCellTooltipProps = {
  url: string
}

export const Scope3TableCellTooltip: FC<Scope3TableCellTooltipProps> = ({ url }) => {
  const { t } = useTranslation()
  const [iconName, setIconName] = useState<"qm-filled" | "qm-stroke">("qm-stroke")
  const userLocale = useAppSelector(selectUserLocale)

  const { data, isLoading } = useGetScope3ConsumptionEmissionFactorsQuery({ url }, { skip: iconName !== "qm-filled" })

  const items = data?.map((item, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Box key={`${item.value}-${index}`} mb={1}>
      <Typography
        variant="body2"
        color="common.white"
      >
        {t("valueChainEmissions:source")}: {item.source}
      </Typography>

      <Typography
        variant="body2"
        color="common.white"
      >
        {t("valueChainEmissions:emissionsFactors")}: {item.value.toLocaleString(userLocale)} {item.unit}
      </Typography>
    </Box>
  ))
  
  return (
    <Tooltip
      title={isLoading ? <ButtonLoader position="unset" /> : items}
      arrow
      placement="right"
      onMouseEnter={() => setIconName("qm-filled")}
      onMouseLeave={() => setIconName("qm-stroke")}
    >
      <span>
        <SvgIcon
          name={iconName}
          color="grey.500"
          ml={1}
        />
      </span>
    </Tooltip>
  )
}
