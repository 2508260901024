import type { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Paper, Typography } from "@mui/material";

export const DictionaryTile: FC = () => {
  const { t } = useTranslation()

  return (
    <Paper sx={{ height: "100%" }}>
      <Typography
        variant="h3"
        fontWeight={500}
        color="primary.main"
        mb={2}
      >
        {t("knowledgeBase:dictionaryTile:title")}
      </Typography>

      <Typography
        variant="body1"
        sx={{ "& span": { fontWeight: 500 }}}
        mb={1.5}
      >
        <Trans i18nKey="knowledgeBase:dictionaryTile:cf">
          <span>CF</span>
          - Carbon Footprint (Greenhouse gas footprint).
        </Trans>
      </Typography>

      <Typography
        variant="body1"
        sx={{ "& span": { fontWeight: 500 }}}
        mb={1.5}
      >
        <Trans i18nKey="knowledgeBase:dictionaryTile:ghg">
          <span>GHG</span>
          - Greenhouse gases. They contribute to climate changes.
        </Trans>
      </Typography>

      <Typography
        variant="body1"
        sx={{ "& span": { fontWeight: 500 }}}
        mb={1.5}
      >
        <Trans i18nKey="knowledgeBase:dictionaryTile:ghgProtocole">
          <span>GHG Protocole</span>
          - Standard for Greenhouse gases.
        </Trans>
      </Typography>
    </Paper>
  )
}