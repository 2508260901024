import { theme } from "../../theme/theme";
import { createFallbackColorGenerator, getFallbackColors } from "../../utils/fallbackColors";
import type { LookUpSimpleResponseModel } from "../../store/lookups/lookups.types";
import type { UtilityAggregateResponseModel } from "../../store/widgetContents/widgetContents.types";

export const parseDurations = (durations?: LookUpSimpleResponseModel[]) => durations?.map(({ value, display }) => ({
  value,
  label: display
}))

export const parseUtilityAggregates = (aggregates?: UtilityAggregateResponseModel[]) => {
  const fallbackColors = getFallbackColors(theme.palette.chart)
  const colorGenerator = createFallbackColorGenerator(fallbackColors)

  return aggregates?.map(({
    utilityId,
    carbonEquivalent,
    utilityName,
    colour
  }) => ({
    id: utilityId,
    value: carbonEquivalent,
    name: utilityName,
    color: colour ? `#${colour}` : colorGenerator(),
  }))
}