import { apiService } from "../store.service";

import { processImportConfig } from "./imports.config";

export const ImportsApi = apiService
  .enhanceEndpoints({
    addTagTypes: ["widgetFuelConsumptions", "widgetUtilityConsumptions", "widgetRenewablesConsumptions"]
  })
  .injectEndpoints({
    endpoints: build => ({
      processImport: build.mutation<void, number>({
        query: (importId) => ({
          ...processImportConfig,
          params: {
            importId
          }
        }),
        invalidatesTags: ["widgetFuelConsumptions", "widgetUtilityConsumptions", "widgetRenewablesConsumptions"]
      }),
    }),
  });

export const {
  useProcessImportMutation,
} = ImportsApi