import { Accordion, AccordionDetails, AccordionSummary, ListItem, styled } from "@mui/material";

export const StyledListItem = styled(ListItem)(({
  padding: 0,
  "& .MuiPaper-root": {
    borderRadius: 0,
  }
}))

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: "100%",
  padding: 0,
  overflow: "visible",
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  transition: `${theme.transitions.duration.standard}ms all`,
}))

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  display: "flex",
  margin: 0,
  padding: theme.spacing(1,0),
  minHeight: "unset",
  "&.Mui-expanded": {
    minHeight: "unset",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0
    },
  },
}))

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0,0,2,1)
}))