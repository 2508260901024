import type { FC } from "react"
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks";
import { useYupResolver } from "../../../../hooks/useYupResolver";
import { useLogInMutation } from "../../../../store/auth/auth.api";
import { selectUserData } from "../../../../store/auth/auth.selectors";
import { clearSession } from "../../../../store/auth/auth.slice";
import { useChangeUserPasswordMutation } from "../../../../store/users/users.api";
import { RouteConfig } from "../../../../components/App/Routes/routes.config";
import { Button } from "../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import { TextFieldController } from "../../../../components/common/TextFieldController/TextFieldController.component";
import type { NewPasswordFormState } from "./NewPasswordForm.types";
import { StyledLogoutButton } from "./NewPasswordForm.styles";

import { NewPasswordFormValidationSchema } from "./NewPasswordForm.validation";

export const NewPasswordForm: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userData = useAppSelector(selectUserData)
  const dispatch = useAppDispatch()

  const [ changePassword ] = useChangeUserPasswordMutation()
  const [ logIn, { isLoading } ] = useLogInMutation()

  const form = useForm<NewPasswordFormState>({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    mode: "all",
    resolver: useYupResolver(NewPasswordFormValidationSchema),
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    const { currentPassword, newPassword } = values

    try {
      await changePassword({
        currentPassword,
        newPassword
      }).unwrap()
      await logIn({
        username: userData.email,
        password: newPassword
      }).unwrap()
      navigate(RouteConfig.DASHBOARD.fullPath);
    } catch (error) {
      form.setError("newPassword", {
        message: t("errors:globalError"),
      });
    }
  })

  const handleLogout = () => {
    dispatch(clearSession())
  }
  
  return (
    <FormProvider {...form}>
      <Box
        component="form"
        onSubmit={handleSubmit}
      >
        <TextFieldController
          type="password"
          name="currentPassword"
          label={t("form:label:temporaryPassword")}
          placeholder={t("form:placeholder:temporaryPassword")}
          autoFocus
          sx={{ mb: 3 }}
          disabled={isLoading}
        />

        <Box sx={{ mb: 3 }}>
          <TextFieldController
            type="password"
            name="newPassword"
            label={t("form:label:newPassword")}
            placeholder={t("form:placeholder:newPassword")}
            disabled={isLoading}
            helperText={t("user:newPasswordInfo")}
          />
        </Box>

        <TextFieldController
          type="password"
          name="confirmPassword"
          label={t("form:label:confirmNewPassword")}
          placeholder={t("form:placeholder:confirmNewPassword")}
          disabled={isLoading}
          sx={{ mb: 4 }}
        />

        <Button
          type="submit"
          fullWidth
          size="large"
          endIcon={isLoading ? <ButtonLoader /> : null}
          disabled={isLoading}
        >
          {t("user:newPasswordSubmitButton")}
        </Button>

        <StyledLogoutButton
          onClick={handleLogout}
          fullWidth
          size="large"
          variant="text"
          color="secondary"
          disabled={isLoading}
        >
          {t("common:logout")}
        </StyledLogoutButton>
      </Box>
    </FormProvider>
  )
}
