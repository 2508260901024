import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper, Typography } from "@mui/material";

import { useUpdateHideDashboardCompanyTileMutation } from "../../../../store/userSettings/userSettings.api";
import { RouteConfig } from "../../../../components/App/Routes/routes.config";
import SvgIcon from "../../../../components/common/SvgIcon/SvgIcon.component";
import { StyledLink } from "./CompleteDataReminder.styles";

export const CompleteDataReminder: FC = () => {
  const { t } = useTranslation()

  const [hideCompanyDataReminder] = useUpdateHideDashboardCompanyTileMutation()

  const handleHideCompanyDataReminder = async () => {
    await hideCompanyDataReminder({ value: true }) 
  }

  return (
    <Paper
      sx={{
        padding: 2,
        marginBottom: 2,
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography>
          {t("widgets:completeData")}
        </Typography>
      
        <StyledLink to={RouteConfig.SETTINGS.fullPath}>
          <Typography
            variant="body1"
            fontWeight={500}
            ml={3}
          >
            {t("widgets:goToSettings")}
          </Typography>
        </StyledLink>

        <SvgIcon
          width="14px"
          height="14px"
          name="close"
          ml="auto"
          onClick={handleHideCompanyDataReminder}
          sx={{ cursor: "pointer" }}
        />
      </Box>
    </Paper>
  )
}