import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, List, Paper, Typography } from "@mui/material";

import { useGetFaqsQuery } from "../../../store/faqs/faqs.api";
import { Loader } from "../../../components/common/Loader/Loader.component";
import SvgIcon from "../../../components/common/SvgIcon/SvgIcon.component";
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, StyledListItem } from "./FaqTile.styles";

export const FaqTile: FC = () => {
  const { t } = useTranslation()
  const { data: faqs, isLoading } = useGetFaqsQuery()

  return (
    <Paper>
      <Typography
        variant="h1"
        color="text.dark"
      >
        {t("knowledgeBase:faq")}
      </Typography>

      <List>
        {isLoading 
          ? <Loader withoutText /> 
          : faqs?.map( ({ faqId, question, answer }) => (
            <StyledListItem key={faqId}>
              <StyledAccordion>
                <StyledAccordionSummary expandIcon={<SvgIcon name="chevron-down" />}>
                  <Box
                    display="flex"
                    alignItems="flex-start"
                  >
                    <Typography
                      variant="h3"
                      ml={1}
                    >
                      {question}
                    </Typography>
                  </Box>
                </StyledAccordionSummary>

                <StyledAccordionDetails>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                  >
                    {answer}
                  </Typography>
                </StyledAccordionDetails>
              </StyledAccordion>
            </StyledListItem>
          ))
        }
      </List>
    </Paper>
  )
}