import type { TFunction } from "i18next";

import { RouteConfig } from "../../App/Routes/routes.config";
import type { SubmenuItemsConfigType } from "../SubmenuList/SubmenuList.types";

export const getExplorerSubmenuItemsConfig = (t: TFunction): SubmenuItemsConfigType[] => [
  {
    text: t("common:menu:menuEmissions:emissions"),
    navigateTo: RouteConfig.EXPLORER.fullPath,
    disabled: false,
  },
  // {
  //   text: t("common:menu:menuEmissions:advice"),
  //   navigateTo: RouteConfig.EXPLORER_ADVICE.fullPath,
  //   disabled: false,
  // },
]