import type { FC } from "react";
import {useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { useLazyGetAllScope3ConsumptionsForInputFormQuery } from "../../../store/scope3Consumptions/scope3Consumptions.api";
import { Loader } from "../../../components/common/Loader/Loader.component";
import { Pagination } from "../../../components/common/Pagination/Pagination.component";
import { Scope3Table } from "./Scope3Table/Scope3Table.component";

type Scope3TableTabProps = {
  inputFormId: number
  locationId: number
}

export const Scope3TableTab: FC<Scope3TableTabProps> = ({ locationId, inputFormId }) => {
  const { t } = useTranslation()
  const [getScope3Consumptions, { data, isFetching, error }] = useLazyGetAllScope3ConsumptionsForInputFormQuery()
  const consumptions = data?.data
  const paginationSettings = data?.meta.pagination ? data.meta.pagination  : undefined
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (!!locationId && !!inputFormId) {
      setPage(1)
      getScope3Consumptions({ locationId, inputFormId })
    }
  }, [locationId, inputFormId])

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage)
    await getScope3Consumptions({ locationId, inputFormId, pageNumber: newPage })
  }

  if (error) {
    return <Typography variant="body1" color="error.main">{t("errors:globalError")}</Typography>
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
    >
      {consumptions && paginationSettings && !isFetching ? (
        <Scope3Table
          consumptions={consumptions}
          locationId={locationId}
        />
      ) : <Loader />}

      {paginationSettings?.TotalPages && paginationSettings.TotalPages > 1 ? (
        <Pagination
          page={page}
          count={paginationSettings.TotalPages}
          onChange={handleChangePage}
        />
      ) : null}
    </Box>
  )
}