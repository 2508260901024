import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Link, Paper, Typography } from "@mui/material";

import { SUPPORT_EMAIL, SUPPORT_PHONE_NUMBER } from "../../../app.config";

export const ContactTile: FC = () => {
  const { t } = useTranslation()

  return (
    <Paper sx={{ height: "100%", bgcolor: "primary.main" }}>
      <Typography
        variant="body1"
        fontWeight={500}
        color="common.white"
        mb={1.5}
      >
        {t("knowledgeBase:contactTile:contactUs")}
      </Typography>

      <Typography
        variant="body1"
        color="common.white"
        mb={1.5}
      >
        {t("knowledgeBase:contactTile:availableHours")}
      </Typography>

      <Box mb={1.5}>
        <Typography
          variant="body1"
          color="common.white"
          mb={0.5}
        >
          {t("knowledgeBase:contactTile:writeToUs")}
        </Typography>

        <Typography
          variant="body1"
          fontWeight={700}
          color="common.white"
          component={Link}
          href={`mailto:${SUPPORT_EMAIL}`}
        >
          {SUPPORT_EMAIL}
        </Typography>
      </Box>

      <Box mb={1.5}>
        <Typography
          variant="body1"
          color="common.white"
          mb={0.5}
        >
          {t("knowledgeBase:contactTile:callUs")}
        </Typography>

        <Typography
          variant="body1"
          fontWeight={700}
          color="common.white"
          component={Link}
          href={`tel:${SUPPORT_PHONE_NUMBER}`}
        >
          {SUPPORT_PHONE_NUMBER}
        </Typography>
      </Box>
    </Paper>
  )
}