import type { FC} from "react";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";

import { useAppSelector } from "../../../hooks/storeHooks";
import { selectUserLocale } from "../../../store/auth/auth.selectors";
import { useGetElectricityConsumptionAggregatesQuery } from "../../../store/widgetContents/widgetContents.api";
import { RouteConfig } from "../../App/Routes/routes.config";
import { PieChart } from "../../charts/PieChart/PieChart.component";
import { Loader } from "../../common/Loader/Loader.component";
import { ErrorTile } from "../ErrorTile/ErrorTile.component";
import { ElectricityUsageTileNoData } from "./ElectricityUsageTileNoData/ElectricityUsageTileNoData.component";
import { parseData } from "./ElectricityUsageTile.utils";
import { StyledPaper } from "../widgets.styles";
import { StyledLink, StyledTypography } from "./ElectricityUsageTile.styles";

export const ElectricityUsageTile: FC<{ title: string }> = ({ title }) => {
  const { t } = useTranslation()
  const { data, isLoading, error } = useGetElectricityConsumptionAggregatesQuery(undefined, { refetchOnMountOrArgChange: true })
  const parsedData = useMemo(() => parseData(data), [data])
  const userLocale = useAppSelector(selectUserLocale)

  if (isLoading) {
    return (
      <StyledPaper>
        <Loader withoutText my="auto" />
      </StyledPaper>
    )
  }

  if (error) {
    return <ErrorTile title={title} />
  }

  if (!parsedData.length) {
    return <ElectricityUsageTileNoData title={title} />
  }

  return (
    <StyledPaper>
      <Typography
        variant="h2"
        color="text.dark"
        mb={1.5}
      >
        {title}
      </Typography>

      <Grid container spacing={3} alignItems="center" height="100%">
        <Grid item xs={5}>
          <PieChart
            data={parsedData}
            minHeight={250}
            legend
          />
        </Grid>

        <Grid item xs={7}>
          {data?.types.map( type => (
            <StyledTypography
              key={`${type.name.replace(/ /gu, "-")}-${type.valuePercentage}`}
              variant="body1"
            >
              <Trans
                i18nKey="widgets:electricityUsageTile:summaryText"
                values={{
                  name: type.name,
                  percent: type.valuePercentage,
                  value: type.value.toLocaleString(userLocale),
                  unit: data.unit,
                }}
                components={{ 1: <span />, 3: <span />, 5: <span /> }}
              >
                {"The share of <1>{{ name }}</1> is <3>{{ percent }}%</3> at <5>{{ value }} {{ unit }}</5>"}
              </Trans>
            </StyledTypography>  
          ))}

          <Typography
            variant="body1"
            color="text.secondary"
            fontWeight={500}
            mb={1.5}
          >
            {t("widgets:electricityUsageTile:checkRecommendations")}
          </Typography>

          <StyledLink to={RouteConfig.SCENARIOS.fullPath.replace("/:scenarioId?", "")}>
            <Typography
              variant="body1"
              fontWeight={500}
            >
              {t("widgets:goToScenarios")}
            </Typography>
          </StyledLink>
        </Grid>
      </Grid>

    </StyledPaper>
  )
}