import { theme } from "../../../theme/theme";
import { createFallbackColorGenerator, getFallbackColors } from "../../../utils/fallbackColors";
import type { LookUpSimpleResponseModel } from "../../../store/lookups/lookups.types";
import type { FuelAggregateResponseModel } from "../../../store/widgetContents/widgetContents.types";

export const parseDurations = (durations?: LookUpSimpleResponseModel[]) => durations?.map(({ value, display }) => ({
  value,
  label: display
}))

export const parseFuelAggregates = (aggregates?: FuelAggregateResponseModel[]) => {
  const fallbackColors = getFallbackColors(theme.palette.chart)
  const colorGenerator = createFallbackColorGenerator(fallbackColors)

  return aggregates?.map(({
    fuelId,
    percentage,
    fuelName,
    colour
  }) => ({
    id: fuelId,
    value: percentage,
    name: fuelName,
    color: colour ? `#${colour}` : colorGenerator(),
  }))
}