import { type FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import type { AxiosResponse } from "axios";

import { useYupResolver } from "../../../../hooks/useYupResolver";
import { usePasswordRequestCodeMutation, usePasswordVerifyCodeMutation } from "../../../../store/users/users.api";
import { RouteConfig } from "../../../../components/App/Routes/routes.config";
import { Button } from "../../../../components/common/Button/Button.component";
import { ButtonLoader } from "../../../../components/common/Button/ButtonLoader/ButtonLoader.component";
import SvgIcon from "../../../../components/common/SvgIcon/SvgIcon.component";
import { InfoLabel } from "../../../../components/common/TextField/InfoLabel/InfoLabel.component";
import { TextFieldController } from "../../../../components/common/TextFieldController/TextFieldController.component";
import type { ApiErrorResponseModel } from "../../../../store/store.types";
import type { ForgotPasswordCodeFormProps, ForgotPasswordCodeFormState } from "./ForgotPasswordCodeForm.types";
import { StyledButton } from "./ForgotPasswordCodeForm.styles";

import { ForgotPasswordCodeFormValidationSchema } from "./ForgotPasswordCodeForm.validation";

export const ForgotPasswordCodeForm: FC<ForgotPasswordCodeFormProps> = ({ email, goBack, onSuccess }) => {
  const { t } = useTranslation()
  const [ verifyCode, { isLoading } ] = usePasswordVerifyCodeMutation()
  const [ passwordRequestCode, { isLoading: resendCodeLoading, error } ] = usePasswordRequestCodeMutation()
  const [resendDelay, setResendDelay] = useState(false)

  const form = useForm<ForgotPasswordCodeFormState>({
    defaultValues: {
      code: "",
    },
    mode: "onSubmit",
    resolver: useYupResolver(ForgotPasswordCodeFormValidationSchema)
  });

  const resendCode = async () => {
    try {
      await passwordRequestCode({ email }).unwrap()
      form.clearErrors()
      setResendDelay(true)
    } catch(err) {}
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    try {
      await verifyCode({ email, code: values.code }).unwrap()
      onSuccess(values.code)
    } catch(e) {
      const err = e as AxiosResponse<ApiErrorResponseModel>
      const errorDetail = err.data.detail
      
      form.setError("code", {
        message : errorDetail ?? t("errors:globalError")
      });
    }
  })

  useEffect(() => {
    if (resendDelay) {
      const tim = setTimeout(() => {
        setResendDelay(false);
      }, 5 * 60 * 1000);

      return () => {
        clearTimeout(tim);
      };
    }
  }, [resendDelay]);

  return (
    <FormProvider {...form}>
      <Box
        component='form'
        onSubmit={handleSubmit}
      >
        <Typography
          variant="h1"
          fontSize={32}
          fontWeight={700}
          lineHeight="40px"
          color="text.dark"
          mb={2}
        >
          {t("user:forgotPasswordCodeHeader")}
        </Typography>

        <Typography
          variant="h3"
          component="p"
          fontWeight={400}
          color="text.secondary"
          mb={4}
        >
          {t("user:forgotPasswordCodeSubheader")}
          &nbsp;
          <Box
            component="span"
            fontWeight={700}
          >
            {email}
          </Box>

          <Button
            variant="text"
            size="tiny"
            onClick={goBack}
            sx={{
              p: 0,
              minWidth: "unset",
              ml: 1,
            }}
          >
            {t("common:change")}
          </Button>
          
        </Typography>

        <TextFieldController
          name="code"
          label={t("form:label:verificationCode")}
          placeholder={t("form:placeholder:verificationCode")}
          sx={{ mb: 4 }}
          disabled={isLoading || resendCodeLoading}
        />

        <Button
          type="submit"
          fullWidth
          size="large"
          endIcon={isLoading ? <ButtonLoader /> : null}
          disabled={isLoading || resendCodeLoading}
          sx={{ mb: 3 }}
        >
          {t("user:forgotPasswordCodeSubmit")}
        </Button>

        <Typography
          variant="body1"
          color="text.secondary"
          textAlign="center"
        >
          {t("user:forgotPasswordCodeResendLabel")}&nbsp;
          
          {resendDelay
            ? t("user:forgotPasswordCodeResendButtonDisabled")
            : (
              <Button
                variant="text"
                size="tiny"
                onClick={resendCode}
                disabled={isLoading || resendCodeLoading}
                sx={{
                  p: 0,
                  minWidth: "unset",
                  lineHeight: "20px"
                }}
              >
                {t("user:forgotPasswordCodeResendButton")}
              </Button>
            )}
        </Typography>

        {error ? (
          <InfoLabel
            invalid={true}
            textAlign="center"
          >
            {t("errors:globalError")}
          </InfoLabel>
        ) : null}

        <StyledButton
          component={NavLink}
          to={RouteConfig.LOGIN.fullPath}
          fullWidth
          size="large"
          variant="text"
          color="secondary"
          disabled={isLoading || resendCodeLoading}
          startIcon={<SvgIcon name="back" />}
        >
          {t("user:forgotPasswordBackToLogin")}
        </StyledButton>
      </Box>
    </FormProvider>
  )
}
