import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, NavLink, Outlet, useLocation, useParams } from "react-router-dom";
import { Tabs } from "@mui/material";

import { PageWrapper } from "../../../layouts/PageWrapper";
import { useGetAllScenariosQuery } from "../../../store/scenarios/scenarios.api";
import { RouteConfig } from "../../../components/App/Routes/routes.config";
import { Loader } from "../../../components/common/Loader/Loader.component";
import { NoScenariosView } from "../NoScenariosView/NoScenariosView.component";
import { StyledTab } from "./ScenariosPage.styles";

export const ScenariosPage: FC = () => {
  const { t } = useTranslation()
  const { scenarioId } = useParams()
  const { pathname } = useLocation()
  
  const { data: scenarios, isLoading: scenariosLoading } = useGetAllScenariosQuery(undefined, { refetchOnMountOrArgChange: true })

  const incorrectScenarioId = 
    scenarios
    && scenarios.length > 0
    && (
      !scenarioId
      || !scenarios.find((scenario) => scenario.scenarioId === Number(scenarioId))
    )
  
  if (incorrectScenarioId) {
    const path = RouteConfig.SCENARIOS.fullPath.replace(":scenarioId?", scenarios[0]?.scenarioId.toString())

    return <Navigate to={path} replace={true}/>;
  }

  if(scenariosLoading) {
    return <Loader />
  }

  if(scenarios && scenarios.length === 0) {
    return <NoScenariosView />
  }

  let currentTab = pathname;
  if (pathname.includes("/scenarios/")) {
    currentTab = "/scenarios/";
  }

  if (pathname.includes("/scenarios/") && pathname.includes("/calculator")) {
    currentTab = "/scenarios/";
  }

  if (pathname.includes("/scenarios/") && pathname.includes("/simulations")) {
    currentTab = "/scenarios/simulations";
  }

  return (
    <PageWrapper
      sx={{
        flexDirection: "row",
        flexWrap:"wrap",
        flex: 0,
        justifyContent: "space-between",
      }}
    >
      <Tabs
        value={currentTab}
        sx={{ mb: 3 }}
      >
        <StyledTab
          label={t("scenarios:scenarioTab")}
          value="/scenarios/"
          component={NavLink}
          to=""
        />
        <StyledTab
          label={t("scenarios:reportsTab")}
          value="/scenarios/simulations"
          component={NavLink}
          to="simulations"
        />
      </Tabs>

      <Outlet context={{ scenarioId: Number(scenarioId) }} />
    </PageWrapper>
  )
}
