import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import { AppProvider } from "./components/App/AppProvider";
import { router } from "./components/App/Routes/router";

ReactDOM.createRoot(document.getElementById("root") as Element).render(
  <AppProvider>
    <RouterProvider router={router} />
  </AppProvider>,
)
