import { useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAppSelector } from "../../../hooks/storeHooks";
import { selectToken, selectUserData } from "../../../store/auth/auth.selectors";
import { RouteConfig } from "../Routes/routes.config";

export const PublicRoutesController = () => {
  const userData = useAppSelector(selectUserData)
  const accessToken = useAppSelector(selectToken)

  const hasAccess = useMemo(() => accessToken && !userData.forcePasswordChange, [userData])

  return hasAccess ? <Navigate to={RouteConfig.DASHBOARD.fullPath} replace /> : <Outlet />
}