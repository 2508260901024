import type { FC } from "react";
import {useEffect, useState } from "react";
import { Box } from "@mui/material";

import { useLazyGetFuelsConsumptionsQuery } from "../../../../store/fuelConsumptions/fuelConsumptions.api"
import { Loader } from "../../../../components/common/Loader/Loader.component"
import { Pagination } from "../../../../components/common/Pagination/Pagination.component";
import { LocationFuelsTable } from "../../../../components/LocationsModal/LocationForms/LocationWorkspace/Fuels/LocationFuelsTable/LocationFuelsTable.component"

type FuelsDataTabProps = {
  locationId: number
}

export const FuelsDataTab: FC<FuelsDataTabProps> = ({ locationId }) => {
  const [getFuelsConsumptions, { data, isFetching }] = useLazyGetFuelsConsumptionsQuery()
  const fuels = data?.data
  const paginationSettings = data?.meta.pagination ? data.meta.pagination  : undefined
  const [page, setPage] = useState(1)

  useEffect(() => {
    setPage(1)
    getFuelsConsumptions({ locationId })
  }, [locationId])

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage)
    await getFuelsConsumptions({ locationId, pageNumber: newPage })
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
    >
      {fuels && paginationSettings && !isFetching ? (
        <LocationFuelsTable
          locationId={locationId}
          fuels={fuels}
        />
      ) : <Loader />}

      {paginationSettings?.TotalPages && paginationSettings.TotalPages > 1 ? (
        <Pagination
          page={page}
          count={paginationSettings.TotalPages}
          onChange={handleChangePage}
        />
      ) : null}
    </Box>
  )
}