import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";

import { RegisterForm } from "./RegisterForm/RegisterForm.component";
import { TokenValidationForm } from "./TokenValidationForm/TokenValidationForm.component";

/*
b7677c89-56d2-4f1a-8a95-8502c163fa8f used
05074338-6ff7-4ad4-b328-2fd73cbdd062 used
1e40ada9-b59f-456e-b007-4925ea33ca66 used
b625cf05-a112-4846-9ccf-f1f7c7565a54
d131a849-e63d-48e0-ad0a-1ae29cde0e0d
fb2427fa-bd96-4b58-a200-a6922e99dc71
03a4ff61-1905-4d94-85e1-5ddb7d9bedec
9cb27190-7008-4953-9921-fe444b0c580c
213fea44-8785-4d68-a3ba-d925f53bff9f
b199612b-4d5a-4e5a-be0b-8280ee0b90a2
e6bfba4f-4dde-4084-bc30-922456416414
41c4f60c-7733-4a0e-bbb2-0ad359fd57b5
cb3f4a55-724a-4124-b836-0a989034575b
fb4ede43-976e-4836-ac1d-bf0f9585ed6f
945b251f-69b9-443c-bbcf-edd67063f9a2
33c6ef7c-cf70-4e21-85bf-0c51d34f27aa
679dd132-7cbf-4004-86b6-7d89264650a3
f52434a8-c2ef-45c5-977a-871c87d18b79
1cb56967-6706-4cbc-b0bf-089770592ba6
*/

export const InvitationPage = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const token = searchParams.get("token")

  return (
    <>
      <Typography
        variant="h1"
        fontSize={32}
        fontWeight={700}
        lineHeight="40px"
        color="text.dark"
        mb={2}
      >
        {t("user:invitationPage:header")}
      </Typography>
      
      {token 
        ? <RegisterForm token={token} />
        : <TokenValidationForm />
      }
    </>
  )
}
