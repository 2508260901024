import { useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import type { SelectChangeEvent} from "@mui/material";
import dayjs from "dayjs";

import { useAppDispatch, useAppSelector } from "../../../../../../hooks/storeHooks";
import { useYupResolver } from "../../../../../../hooks/useYupResolver";
import { useLazyLogInByTokenQuery } from "../../../../../../store/auth/auth.api";
import { selectUserData } from "../../../../../../store/auth/auth.selectors";
import { setFuelConsumptions, setFuelEditMode, updateFuelConsumption } from "../../../../../../store/consumptions/consumptions.slice";
import { useGetAllFuelCategoriesQuery } from "../../../../../../store/fuelCategories/fuelCategories.api";
import { useDeleteFuelConsumptionMutation, usePostFuelConsumptionMutation, useUpdateFuelConsumptionMutation } from "../../../../../../store/fuelConsumptions/fuelConsumptions.api";
import { useLazyGetAvailableFuelsByLocationQuery } from "../../../../../../store/fuels/fuels.api";
import { useLazyGetUnitConversionFactorsByUnitQuery } from "../../../../../../store/units/units.api";
import { parseFuelCategoriesResponse, parseFuelTypesResponse, parseUnitsResponse } from "./LocationFuelsForm.utils";
import type { FuelConsumptionResponseModel } from "../../../../../../store/fuelConsumptions/fuelConsumptions.types";
import type { AutocompleteOption } from "../../../../../common/Autocomplete/Autocomplete.types";
import type { FuelValueType, LocationFuelsFormProps, LocationFuelsFormState } from "./LocationFuelsForm.types";

import { FuelConsumptionValidationSchema } from "./LocationFuelsForm.validation";

export const useLocationFuelsForm = ({
  locationId,
  defaultValues,
  closeEditMode,
  consumptionId
}: LocationFuelsFormProps) => {
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectUserData)
  const [loginByToken] = useLazyLogInByTokenQuery()

  const {
    data: fuelCategories,
    error: fuelCategoriesError,
  } = useGetAllFuelCategoriesQuery({ locationId })

  const [getAvailableFuelsByLocation, {
    data: fuels,
    isLoading: isFuelsLoading,
    error: fuelsError,
  }] = useLazyGetAvailableFuelsByLocationQuery()

  const [getUnitConversionsFactorsByUnit, {
    data: units,
    isLoading: isUnitsLoading,
    error: isUnitsError
  }] = useLazyGetUnitConversionFactorsByUnitQuery()

  const [postFuelConsumption, {
    isLoading: postFuelConsumptionLoading,
    error: postFuelConsumptionError
  }] = usePostFuelConsumptionMutation()

  const [deleteFuelConsumption, {
    isLoading: deleteFuelConsumptionLoading,
    error: deleteFuelConsumptionError
  }] = useDeleteFuelConsumptionMutation()

  const [updateFuelConsumptionMutation, {
    isLoading: updateFuelConsumptionLoading,
    error: updateFuelConsumptionError
  }] = useUpdateFuelConsumptionMutation()

  const globalErrors = [
    fuelCategoriesError,
    fuelsError,
    isUnitsError,
    postFuelConsumptionError,
    deleteFuelConsumptionError,
    updateFuelConsumptionError,
  ].filter(error => error)
  
  const form = useForm<LocationFuelsFormState>({
    defaultValues: {
      category: defaultValues?.fuelCategoryId ?? "",
      type: defaultValues ? {
        value: {
          fuelId: Number(defaultValues.fuelId),
          fuelVariantId: defaultValues.fuelVariantId ? Number(defaultValues.fuelVariantId) : null,
          unitId: Number(defaultValues.unitId),
        },
        label: defaultValues.fuelVariantName ?? defaultValues.fuelName,
      } : null,
      amount: defaultValues?.value ?? "",
      unit: defaultValues?.unitId ?? "",
      relatedInvoice: defaultValues?.reference ?? "",
      date: defaultValues?.date ?? ""
    },
    mode: "all",
    resolver: useYupResolver(FuelConsumptionValidationSchema),
  })

  const [categoryValue, typeValue] = useWatch({
    control: form.control,
    name: ["category", "type"]
  })

  useEffect(() => {
    const fetchOptions = async () => {
      if(defaultValues) {
        await getAvailableFuelsByLocation({ locationId, categoryId: defaultValues.fuelCategoryId })
        await getUnitConversionsFactorsByUnit({ unitId: defaultValues.unitId, includeDest: true })
      }
    }
    fetchOptions()
  }, [defaultValues])

  useEffect(() => {
    dispatch(setFuelEditMode(form.formState.isDirty))
  }, [form.formState.isDirty])

  const parsedFuelCategories = useMemo(() => parseFuelCategoriesResponse(fuelCategories), [fuelCategories])
  const parsedFuelTypes = useMemo(() => parseFuelTypesResponse(fuels), [fuels])
  const parsedUnits = parseUnitsResponse(units)

  const handleUpdate = form.handleSubmit(async (values) => {
    if(!consumptionId) {
      return null
    }

    const payload = {
      locationId,
      fuelId: values.type?.value.fuelId,
      fuelVariantId: values.type?.value.fuelVariantId ?? null,
      value: Number(values.amount),
      unitId: Number(values.unit),
      reference: values.relatedInvoice ? values.relatedInvoice : undefined,
      date: dayjs(values.date).format()
    }

    try {
      const data = await updateFuelConsumptionMutation({ consumptionId, payload }).unwrap()

      const category = parsedFuelCategories?.filter( (fuelCategory) => fuelCategory.value === Number(values.category))

      const tableData: FuelConsumptionResponseModel = {
        ...data,
        fuelCategoryId: Number(values.category),
        fuelCategoryName: category?.[0].label ?? "",
      }

      dispatch(updateFuelConsumption(tableData))

      closeEditMode?.()
    } catch (err) {}
  })

  const handleSubmit = form.handleSubmit(async (values) => {
    const payload = {
      locationId,
      fuelId: values.type?.value.fuelId,
      fuelVariantId: values.type?.value.fuelVariantId ?? null,
      value: Number(values.amount),
      unitId: Number(values.unit),
      reference: values.relatedInvoice ? values.relatedInvoice : undefined,
      date: dayjs(values.date).format()
    }

    try {
      const data = await postFuelConsumption(payload).unwrap()

      if(!userData.anyConsumptions) {
        loginByToken()
      }

      const category = parsedFuelCategories?.filter( (fuelCategory) => fuelCategory.value === Number(values.category))

      const tableData: FuelConsumptionResponseModel = {
        ...data,
        fuelCategoryId: Number(values.category),
        fuelCategoryName: category?.[0]?.label ?? "",
      }

      dispatch(setFuelConsumptions(tableData))
      form.reset()
    } catch (error) {}
  })

  const handleChangeCategory = (event: SelectChangeEvent<unknown>) => {
    const categoryId = event.target.value as number
    form.setValue("type", null)
    form.setValue("amount", "")
    form.setValue("unit", "")
    getAvailableFuelsByLocation({ locationId, categoryId })
  }

  const handleChangeType = async (data: AutocompleteOption<FuelValueType>) => {
    const { value: { unitId }} = data
    const { data: unitConversions } = await getUnitConversionsFactorsByUnit({ unitId, includeDest: true })
    form.setValue("amount", "")
    if(unitConversions) {
      form.setValue("unit", unitConversions[0].unitId)
    }
  }

  const handleDeleteConsumption = async () => {
    if(consumptionId) {
      await deleteFuelConsumption({ consumptionId }).unwrap()
      closeEditMode?.()
    }
  }

  return {
    form,
    parsedFuelCategories,
    parsedFuelTypes,
    parsedUnits,
    handleChangeCategory,
    handleChangeType,
    isFuelsLoading,
    isUnitsLoading,
    categoryValue,
    typeValue,
    handleUpdate,
    handleSubmit,
    handleDeleteConsumption,
    globalErrors,
    postFuelConsumptionLoading,
    updateFuelConsumptionLoading,
    deleteFuelConsumptionLoading,
  }
}