import type { FC, ReactNode } from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";

import { theme } from "../../../../theme/theme";
import type { ParagraphReportModel, TableColumnModel, TableDataModel, TableMetadataModel, TableRowModel } from "../../../../store/reportHeaders/reportHeaders.types";

const styles = StyleSheet.create({
  tableTitleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1, 0),
  },
  tableTitle: {
    fontSize: "12px",
    fontWeight: 500,
    color: theme.palette.grey[900],
  },
  tableSubtitle: {
    fontSize: "12px",
    color: theme.palette.grey[500],
  },

  tableHeaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1, 0),
  },
  tableHeader: {
    fontSize: "12px",
    fontWeight: 500,
    color: theme.palette.grey[900],
  },
  tableSubheader: {
    fontSize: "8px",
    fontWeight: 500,
    color: theme.palette.grey[500],
  },
  table: {
    display: "flex",
    width: "100%",
    border: 0,
    marginBottom: theme.spacing(1),
  },

  tableRow: {
    width: "100%",
    margin: "auto",
    flexDirection: "row",
    fontSize: "8px",
    fontWeight: 400,
    border: `1px solid ${theme.palette.grey[200]}`,
    borderTop: 0,
  },
  tableRowNested: {
    width: "100%",
    margin: "auto",
    flexDirection: "row",
    fontSize: "8px",
    fontWeight: 400,
    border: `1px solid ${theme.palette.grey[200]}`,
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
  },
  tableRowNestedLastChild: {
    borderBottom: 0,
  },
  tableHeadRow: {
    margin: "auto",
    width: "100%",
    flexDirection: "row",
    backgroundColor: theme.palette.grey[200],
    fontSize: "8px",
    fontWeight: 500,
  },
  tableCol: {
    width: "100%",
    border: `1px solid ${theme.palette.grey[200]}`,
    borderTop: 0,
    borderBottom: 0,
    borderLeft: 0,
  },
  tableColNested: {
    width: "100%",
  },
  tableColNestedLastChild: {
    borderRight: 0,
  },
  tableCell: {
    margin: "8px 4px",
  },
})

export const isTableDataModel = (
  obj: unknown
): obj is TableDataModel => (
  typeof obj === "object" &&
    obj !== null &&
    "columns" in obj &&
    "rows" in obj
);

export const isTableMetadata = (
  obj: unknown
): obj is TableMetadataModel => (
  typeof obj === "object" &&
    obj !== null
);

export type PDFTableProps = {
  paragraph: ParagraphReportModel
}

export const PDFTable: FC<PDFTableProps> = ({ paragraph }) => {

  if (!isTableDataModel(paragraph.data)) return null

  const createRows = (
    rows: TableRowModel[],
    columns: TableColumnModel[],
    nested?: boolean,
  ): ReactNode => 
    rows.map((row, index) => {
      const rowKeys = Object.keys(row).filter(key => row[key as keyof TableRowModel]);
      const columnsCount = columns.length;
      const rowCount = rowKeys.length;

      return (
      // eslint-disable-next-line react/no-array-index-key
        <View key={`row-${index}`} style={{
          ...styles.tableRow,
          ...(nested ? styles.tableRowNested : {}),
          ...(nested && index === rows.length - 1 ? styles.tableRowNestedLastChild : {}),
        }}>
          {columns.map((column, ind) => {
            let colWidth;
            if (rowCount >= columnsCount) {
              colWidth = `${100 / columnsCount}%`;
            } else {
              if (ind < rowCount - 1) {
                colWidth = `${100 / columnsCount}%`;  // Pierwszy element wypełnia pierwszą kolumnę
              } else {
                colWidth = `${100 - (100 / columnsCount)}%`;  // Pozostałe elementy dzielą resztę szerokości
              }
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return row[column.name] && (
              <View
              // eslint-disable-next-line react/no-array-index-key
                key={`${index}-${ind}`}
                style={{
                  ...styles.tableCol,
                  ...(nested ? styles.tableColNested : {}),
                  ...(ind === Object.keys(row).length - 1 ? styles.tableColNestedLastChild : {}),
                  width: colWidth
                }}
              >
                {row[column.name as keyof TableRowModel].type === "string" ? (
                  <Text
                    wrap={false}
                    style={styles.tableCell}
                  >
                    {column.name ? row[column.name as keyof TableRowModel].value : ""}
                  </Text>
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                ) : (
                  <View>
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access */}
                    {createRows(row[column.name].rows, row[column.name].columns, true)}
                  </View>
                )}
              </View>
            )
          })}
        </View>
      )
    })

  return (
    <>
      {isTableMetadata(paragraph.metadata) && (paragraph.metadata.title || paragraph.metadata.subtitle)
        ? (
          <View style={styles.tableTitleWrapper}>
            {paragraph.metadata.subtitle
              ? (
                <Text style={styles.tableSubtitle}>
                  {paragraph.metadata.subtitle}
                </Text>
              )
              : null
            }

            {paragraph.metadata.title
              ? (
                <Text style={styles.tableTitle}>
                  {paragraph.metadata.title}
                </Text>
              )
              : null
            }
          </View>
        )
        : null
      }

      {isTableMetadata(paragraph.metadata) && (paragraph.metadata.headerTitle || paragraph.metadata.headerSubtitle) ? (
        <View style={styles.tableHeaderWrapper}>
          {paragraph.metadata.headerTitle ? (
            <Text style={styles.tableHeader}>
              {paragraph.metadata.headerTitle}
            </Text>
          ) : null}

          {paragraph.metadata.headerSubtitle ? (
            <Text style={styles.tableSubheader}>
              {paragraph.metadata.headerSubtitle}
            </Text>
          ) : null}
        </View>
      ) : null}

      <View style={styles.table}>
        {isTableDataModel(paragraph.data) && (
          <View>
            <View style={styles.tableHeadRow}>
              {paragraph.data.columns.map(col => (
                <View key={col.name} style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {col.title}
                  </Text>
                </View>
              ))}
            </View>

            {createRows(paragraph.data.rows, paragraph.data.columns)}
          </View>
        )}
      </View>
    </>
  )
};