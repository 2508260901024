import type { TFunction } from "i18next";

import { RouteConfig } from "../../App/Routes/routes.config";

export const shortcutsConfig = (t: TFunction) => [
  {
    id: 1,
    label: t("widgets:shortcutsTile:navToLocations"),
    navigateTo: RouteConfig.LOCATIONS.fullPath.replace("/:locationId?", ""),
  },
  {
    id: 2,
    label: t("widgets:shortcutsTile:navToReports"),
    navigateTo: RouteConfig.REPORTS.fullPath.replace("/:reportId?", ""),
  },
  {
    id: 3,
    label: t("widgets:shortcutsTile:navToScenarios"),
    navigateTo: RouteConfig.SCENARIOS.fullPath.replace("/:scenarioId?", ""),
  },
]