import type { FC } from "react";
import { useMemo} from "react";
import type { PaperProps} from "@mui/material";
import { Box, List, Typography } from "@mui/material";

import bookUrl from "../../../assets/img/guides-book.png"
import { useGetUserGuidesQuery } from "../../../store/widgetContents/widgetContents.api";
import { Loader } from "../../common/Loader/Loader.component";
import { Tooltip } from "../../common/Tooltip/Tooltip.component";
import { DownloadGuideItem } from "./DownloadGuideItem/DownloadGuideItem.component";
import { parseResponse } from "./GuidesTile.utils";
import { StyledPaper } from "../widgets.styles";
import { StyledImageWrapper } from "./GuidesTile.styles";

type GuidesTileProps = {
  title?: string
  image?: boolean
  paperProps?: PaperProps
}

export const GuidesTile: FC<GuidesTileProps> = ({ title, image, paperProps }) => {
  const { data, isLoading } = useGetUserGuidesQuery()
  const guides = useMemo(() => parseResponse(data), [data])

  return (
    <StyledPaper {...paperProps}>
      {isLoading ? <Loader withoutText /> : (
        <>
          {title ? (
            <Typography
              variant="h2"
              color="text.dark"
            >
              {title}
            </Typography>
          ) : null}

          <Box
            display="flex"
            justifyContent="space-between"
          >
            <List>
              {guides.map(({ name, fileName, description }) => (
                <Tooltip
                  key={fileName}
                  title={description}
                  placement="top-start"
                >
                  <span>
                    <DownloadGuideItem
                      name={name}
                      fileName={fileName}
                    />
                  </span>
                </Tooltip>
              ))}
            </List>

            {image ? (
              <StyledImageWrapper>
                <img src={bookUrl} alt="" />
              </StyledImageWrapper>
            ) : null}
          </Box>
        </>
      )}
    </StyledPaper>
  )
}