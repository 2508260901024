import { theme } from "../../../theme/theme";
import { createFallbackColorGenerator, getFallbackColors } from "../../../utils/fallbackColors";
import type { ElectricityByTypeResponseModel } from "../../../store/widgetContents/widgetContents.types";
import type { EntryValue, LineChartDataModel } from "../../charts/LineChart/LineChart.types";

export const mergeData = (inputData: ElectricityByTypeResponseModel): LineChartDataModel[] => {
  const outputData: LineChartDataModel[] = [];
  const fallbackColors = getFallbackColors(theme.palette.chart)
  const colorGenerator = createFallbackColorGenerator(fallbackColors)

  inputData.types.forEach((type) => {
    const lineColor = type.colour
      ? type.colour
      : colorGenerator()
    
    type.entries.forEach((entry) => {
      const entryValue: EntryValue = {
        color: lineColor,
        name: type.name,
        value: entry.value,
      };

      const existingEntry = outputData.find((data) => data.date === entry.date);

      if (existingEntry) {
        existingEntry.entries[type.name.replace(/ /gu,"")] = entryValue;
      } else {
        const newEntry: LineChartDataModel = {
          date: entry.date,
          entries: { [type.name.replace(/ /gu,"")]: entryValue },
          unit: inputData.unit,
        };

        outputData.push(newEntry);
      }
    });
  });

  return outputData;
}